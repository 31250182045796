export const svg_node_image = '/public/images/sphere_gouraud_small.png';
export const _DEFAULT_AVATAR = '/public/images/avatar_gender_neutral.png';
export const _TOKEN_INVITE = 'token_invite';
export const _SOLIDIFI_ROOT = 'solidifi';
export const _SOLIDIFI_CONNECTIONS = 'connections';
export const _SOLIDIFI_USERNAME = 'username';
export const makeSolidConnectionsKey = username => {
  return username ? [_SOLIDIFI_ROOT, username, _SOLIDIFI_CONNECTIONS].join('.') : null;
};
export const makeSolidUsernameKey = username => {
  return [_SOLIDIFI_ROOT, _SOLIDIFI_USERNAME].join('.');
};
