import {
  DECISIONS_REQUEST_START,
  DECISION_REQUEST_START,
  SET_DECISIONS_DATA,
  SET_DECISIONS_ERROR,
  SET_DECISION_DATA, // decision details
  SET_DECISION_ERROR,
  SET_CURRENT_OPTION_DATA,
  //   ADD_OPTION_TO_DECISION,
  ADD_OPTION_TO_DECISION_ERROR,
  //   ADD_ANSWER_TO_DECISION,
  ADD_ANSWER_TO_DECISION_ERROR,
  CREATE_DECISION_ERROR,
  UPDATE_VOTE_IN_CURRENT_DECISION,
  CLEAR_ALL_DECISIONS_DATA,
  CLEAR_DECISION_DATA
} from 'shared/constants/actionTypes';
import { nooCommandDirect } from './nooApi';
import { getPersonIdFromState } from './noo';
import { derivePrefixedNodeId } from 'utils';
import _ from 'lodash';

const decisionsRequestStart = () => ({ type: DECISIONS_REQUEST_START });
const decisionRequestStart = () => ({ type: DECISION_REQUEST_START });

const setDecisionsData = data => ({
  type: SET_DECISIONS_DATA,
  payload: data
});

const setDecisionsError = error => ({
  type: SET_DECISIONS_ERROR,
  payload: error
});

const setCurrentDecisionData = data => ({
  type: SET_DECISION_DATA,
  payload: data
});

const setCurrentDecisionError = error => ({
  type: SET_DECISION_ERROR,
  payload: error
});

export const setAddOptionError = error => ({
  type: ADD_OPTION_TO_DECISION_ERROR,
  payload: error
});

const setAddAnswerError = error => ({
  type: ADD_ANSWER_TO_DECISION_ERROR,
  payload: error
});

const setCreateQuestionError = error => ({
  type: CREATE_DECISION_ERROR,
  payload: error
});

const updateCurrentDecisionVote = data => ({
  type: UPDATE_VOTE_IN_CURRENT_DECISION,
  payload: data
});

export const setCurrentOptionData = option => ({
  type: SET_CURRENT_OPTION_DATA,
  payload: option
});

export const searchDecisionsByGroupId = ({ groupId }) => {
  return async (dispatch, getState) => {
    if (!groupId) {
      console.warn('searchDecisionsByGroup called, but missing groupId.... abort', groupId);
      return null;
    }

    const state = getState().decisions;
    if (state?.isRequesting) {
      console.warn(
        'searchDecisionsByGroup in process, but called again! skip the call.... searching for: ',
        groupId
      );
      return null;
    }

    dispatch(decisionsRequestStart());
    const payload = { query_name: 'aqlGetDecisions', data: { group_id: groupId } };
    const params = {
      payload,
      setLoading: () => {},
      setResponse: data => {
        // console.log('check response data format:', data);
        dispatch(setDecisionsData(data?.result?.[0]));
      },
      setResponseError: error => {
        dispatch(setDecisionsError(error));
      }
    };
    nooCommandDirect('aql', params);
  };
};

export const getQuestionDataById = ({ questionId }) => {
  return async (dispatch, getState) => {
    if (!questionId) {
      console.warn('missing questionId, cannot load question data');
      return;
    }

    const state = getState().decisions;
    if (state?.isRequesting) {
      console.warn('decisions requesting already, abort');
      return;
    }

    dispatch(decisionRequestStart());

    const question_id = derivePrefixedNodeId(questionId, 'Questions');
    const person_id = getPersonIdFromState(getState());

    const payload = { query_name: 'aqlQuestionData', data: { question_id, person_id } };
    const params = {
      payload,
      setLoading: () => {},
      setResponse: data => {
        // console.log('check response data format:', data);
        const result = data?.result?.[0];
        if (result.question) {
          dispatch(setCurrentDecisionData(result));
        } else {
          dispatch(setCurrentDecisionError(`Question ${question_id} not found in this bundle.`));
        }
      },
      setResponseError: error => {
        dispatch(setCurrentDecisionError(error));
      }
    };
    nooCommandDirect('aql', params);
  };
};

export const addOptionToDecision = ({ questionId, label, description, data }) => {
  return async (dispatch, getState) => {
    if (!questionId || !label || description == null) {
      console.warn('missing questionId or label or description, cannot add option to question');
      alert('You must provide a title and a description');
      return;
    }

    const state = getState().decisions;
    const { currentDecision = {} } = state;
    if (state?.isRequesting) {
      console.warn('decisions requesting already, abort');
      return;
    }

    const question_id = derivePrefixedNodeId(questionId, 'Questions');
    const personId = getPersonIdFromState(getState());

    const option = {
      data: { label: label.trim(), description: description.trim(), data },
      published: true,
      creator: personId
    };
    setAddOptionError(null);
    const params = {
      question_id,
      option,
      setLoading: () => {},
      setResponse: data => {
        const decision = currentDecision || state.list.find(one => one._id == question_id);
        if (decision) {
          const current_ids = (decision.options || []).map(one => one._id);
          // console.log('check response data format:', data, current_ids);
          // Test if it's a new option or duplicate. Alert if latter.
          let dupe = null;
          data.nodes?.forEach(node => {
            if (current_ids.includes(node.option?._id)) dupe = node.option;
          });
          if (dupe) {
            const msg =
              'New option not added because one with the same name (case insensitive) already exists.';
            dispatch(setAddOptionError(msg));
          } else {
            // TODO: no need to round-trip here, let's just update the local question data
            // for now, just round-trip till I figure out the structure to update
            dispatch(getQuestionDataById({ questionId: question_id }));
          }
        } else {
          console.log('did not find the right decision on response');
        }
      },
      setResponseError: error => {
        alert('Unable to add option' + error);
        dispatch(setAddOptionError(error));
      }
    };
    nooCommandDirect('createOption', params);
  };
};

export const normalize_votes = (vote_dict, max_val = 1) => {
  const vals = Object.values(vote_dict);
  let total = _.sum(vals);
  if (total > 0) {
    Object.entries(vote_dict).forEach(pair => {
      const [key, val] = pair;
      if (isFinite(val)) {
        vote_dict[key] = val / total;
      } else {
        console.log('value not a number', key, val);
      }
    });
  }
  return vote_dict;
};

export const addAnswerToDecision = ({ questionId, optionId, vote }) => {
  // vote in is value of vote for optionId. Confused with data.vote which is a dict of all options voted on and their value
  return async (dispatch, getState) => {
    if (!questionId || !optionId) {
      console.warn('missing questionId or optionId, cannot add answer to question');
      return;
    }

    const state = getState().decisions;
    if (state?.isRequesting) {
      console.warn('decisions requesting already, abort');
      return;
    }

    const question_id = derivePrefixedNodeId(questionId, 'Questions');
    const personId = getPersonIdFromState(getState());

    const { currentDecision = {} } = state;
    const { my_answer } = currentDecision;

    const answer = { questionId: question_id, personId, data: { vote: {} } };
    const vdict = my_answer?.[0]?.data?.vote;
    if (vdict) {
      answer.data.vote = { ...vdict };
    }
    answer.data.vote[optionId] = vote; // setting changed value
    normalize_votes(answer.data.vote); // Saved as totaling 1.
    const params = {
      question_id,
      answer,
      setLoading: () => {},
      setResponse: data => {
        // console.log('check response data format [addAnswerToDecision]:', data);
        // TODO: no need to round-trip here, let's just update the local question data
        // for now, just round-trip till we figure out the structure to update
        dispatch(getQuestionDataById({ questionId: question_id }));
        // too many moving parts to do the local optimistic update, just go get the new record
        // if (data) {
        //   dispatch(updateCurrentDecisionVote({ optionId, vote }));
        // }
      },
      setResponseError: error => {
        dispatch(setAddAnswerError(error));
      }
    };

    nooCommandDirect('createAnswer', params);
  };
};

export const createDecision = ({ data }) => {
  return async (dispatch, getState) => {
    const { title, prompt, description, group_id, parent_id } = data;
    if (!group_id || !title || !description || !prompt) {
      console.warn(
        'Missing group_id or title or description or prompt. Cannot add decision without those.'
      );
      return;
    }
    const state = getState().decisions;
    if (state?.isRequesting) {
      console.warn('decisions requesting already, abort');
      return;
    }

    const params = {
      data,
      setLoading: () => {},
      setResponse: data => {
        console.log('response from decision create', data);
        // dispatch(getQuestionDataById({ data }));
      },
      setResponseError: error => {
        alert('Unabled to add decision' + error);
        dispatch(setCreateQuestionError(error));
      }
    };
    nooCommandDirect('createDecision', params);
  };
};

export const clearAllDecisions = () => ({ type: CLEAR_ALL_DECISIONS_DATA });
export const clearDecisionData = () => ({ type: CLEAR_DECISION_DATA });
