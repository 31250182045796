import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Text,
  Flex,
  Heading,
  HStack,
  Link,
  Radio,
  RadioGroup,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import UserAuth from 'components/User/UserAuth';
import OneDecision from 'components/Decisions/OneDecision';
import DecisionModal from 'components/Segments/DecisionModal';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
import useNooApi from 'hooks/useNooApi';
import { useDispatch } from 'react-redux';

import { dropNodeIdPrefix, derivePrefixedNodeId } from 'utils';

function Positioning(props) {
  const defaultDecision = 'Questions/3495f3bd-4c05-4dce-80c7-26dd9c91f9d2';
  const defaultGroup = 'Groups/0c73e7c3-47d5-4fcf-9fa4-6048a3d99ef7';
  const [decision, setDecision] = useState(null);
  const [decisionData, setDecisionData] = useState(null);
  const [parentTitle, setParentTitle] = useState('Positioning Exercise');
  const [warned, setWarned] = useState(false);
  const [allDecisions, setAllDecisions] = useState({});
  const [template, setTemplate] = useState(null);
  const [sentence, setSentence] = useState(null);
  const [currentSentence, setCurrentSentence] = useState(null);
  const [topVotes, setTopVotes] = useState(null);
  const { parent_decision } = useParams();
  const { currentGroupId, setGroupId } = useGroups({
    from: 'Positioning'
  });
  const { decisions, paths } = useDecisions();

  const {
    nooCommandDirect,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();
  const dispatch = useDispatch();

  const layout = useMemo(() => {
    return {
      name: { postfix: ' is ', decision: 'Questions/3495f3bd-4c05-4dce-80c7-26dd9c91f9d2' },
      what: { postfix: ' that ', decision: 'Questions/5395cb6c-c2f6-4794-96b1-06464a94da87' },
      that: {
        postfix: '.',
        break: true,
        decision: 'Questions/d8d8f9d7-b1e9-420b-aa0c-c1ccb62d6ecb'
      },
      who: {
        prefix: 'Because of it, ',
        postfix: ' can ',
        decision: 'Questions/91da40a3-03db-48a1-a943-ca33a52f5e67'
      },
      can: {
        postfix: '.',
        break: true,
        decision: 'Questions/eed624dc-c8bf-4b48-99f6-9f5ea5664c50'
      },
      important: {
        prefix: 'This is important because ',
        postfix: '.',
        decision: 'Questions/ba6ba03e-39c2-4d5d-a9d5-e541d4c5f1c3'
      }
    };
  }, []);

  const makeSentence = useCallback(
    template => {
      if (template) {
        const phrases = [['name', 'what', 'that'], ['who', 'can'], ['important']];
        let clauses = [];
        phrases.forEach(phrase => {
          let items = [];
          phrase.forEach(key => {
            const it = template[key];
            const dId = it.decision;
            const dData = decisions.filter(one => one._id == dId)[0];
            if (it.prefix?.length > 0) {
              items.push(<span>{it.prefix || ''}</span>);
            }
            if (it.label) {
              items.push(<strong>{it.label}</strong>);
            } else {
              items.push(
                <DecisionModal
                  decisionId={dId}
                  decisionData={dData}
                  groupId={currentGroupId}
                  label={'_______'}
                />
              );
            }

            if (it.postfix?.length > 0) {
              items.push(<span>{it.postfix || ''}</span>);
            }
          });
          items.push(<br></br>);
          clauses.push(<Text>{items}</Text>);
        });

        return <VStack>{clauses}</VStack>;
      }
    },
    [currentGroupId, decisions]
  );

  useEffect(() => {
    if (topVotes) {
      const query = `LET opts = ${JSON.stringify(topVotes)}
      return MERGE(FOR k in KEYS(opts) RETURN {[k]: DOCUMENT(opts[k]).data.label}
      )`;
      const payload = { query_name: 'aqlFreeform', data: { query: query } };
      const params = {
        payload,
        setLoading: () => {},
        setResponse: response => {
          const labels = response.result[0];
          let current = template;
          Object.keys(labels).forEach(key => {
            if (current[key]) {
              current[key].label = labels[key];
            }
          });
          setCurrentSentence(makeSentence(current));
        },
        setResponseError: error => {
          console.log('error ', error);
        }
      };
      nooCommandDirect('aql', params);
    }
  }, [dispatch, makeSentence, nooCommandDirect, template, topVotes]);

  useEffect(() => {
    if (layout && paths?.length > 0) {
      const them = paths.filter(one =>
        one.includes(derivePrefixedNodeId(parent_decision, 'Questions'))
      );
      let uniqs = new Set();
      them.forEach(path => {
        path.forEach(atom => {
          uniqs.add(atom);
        });
      });
      const these = decisions.filter(one => Array.from(uniqs).includes(one._id));
      const lookup = {
        'Name it': 'name',
        'What is it?': 'what',
        'What does it do?': 'that',
        'Who benefits?': 'who',
        'Can do what?': 'can',
        'Important because?': 'important'
      };
      let temp = {};
      let top = {};
      these.forEach(decision => {
        const title = decision.data.title;
        const key = lookup[title];
        const tally = decision.tally?.bucket_tally;
        if (key) {
          temp[key] = layout[key];
          temp[key].decision = decision._id;
          temp[key].options = tally || {};
        } else {
          setParentTitle(decision.data.title);
        }
        if (tally) {
          let maxx = 0;
          let winner = null;
          Object.entries(tally).forEach(([opt, v]) => {
            const score = v.total || 0;
            if (score >= maxx) {
              winner = opt;
              maxx = score;
            }
          });
          top[key] = winner;
          // top[winner] = key;
        }
        setTopVotes(top);
      });
      setTemplate(temp);
    }
  }, [decisions, layout, parent_decision, paths]);

  const onChangeRadio = dId => {
    setDecision(dId);
    if (window.matchMedia('(orientation: portrait)').matches && !warned) {
      alert('Inquries are best used in Landscape mode (horizontal)');
      setWarned(true);
    }
  };

  const onChangeDecision = e => {
    const dId = e.target.dataset.decision;
    setDecision(dId);
    setDecisionData(decisions.filter(one => one._id == dId)[0]);
  };
  const inquirySx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee'
  };

  const keys = 'name what that who can important'.split(' ');

  useEffect(() => {
    if (template && !sentence) {
      setSentence(makeSentence(template));
    }
  }, [makeSentence, sentence, template]);

  return (
    <Box>
      <Text sx={{ fontWeight: 'bold', fontSize: '30px' }}>{parentTitle}</Text>
      <Text>
        This is a <strong>collective sensemaking exercise</strong>. Our job is to explore how best
        to <strong>communicate what we are working on in the most concise way</strong>. We each{' '}
        <strong>suggest wording</strong> for the blank fields, and/or{' '}
        <strong>upvote existing suggestions</strong>. Follow instructions below.
      </Text>
      <Accordion
        maxWidth={'500px'}
        backgroundColor={'#eee'}
        allowToggle
        defaultIndex={0}
        /* width={width}
            onChange={onChange} */
      >
        <AccordionItem>
          <Heading as='h2'>
            <AccordionButton>
              <Text sx={{ fontWeight: 'bold', fontSize: '18px' }}>Example end product:</Text>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            <Text>
              <strong>Apple</strong> is{' '}
              <strong>a technology company that creates hardware, software and services</strong>.
              Because of Apple, <strong>people at all levels of expertise</strong> can{' '}
              <strong>easily access, understand and utilize technology</strong>. This is important
              because{' '}
              <strong>
                lowering the barriers to entry for engaging with technology means more people have
                access to the opportunities it can afford
              </strong>
              .{' '}
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <Heading as='h2'>
            <AccordionButton>
              <Text sx={{ fontWeight: 'bold', fontSize: '18px' }}>Instructions:</Text>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            <Text>
              It is best to work on all the fields before looking at the current top version below.
            </Text>
            <Text>Click on each blank field to expose its suggestions.</Text>
            <Text>Click &quot;Open&quot; to interact.</Text>
            <Text>
              Click checkbox of those you like. Turn on sliders if you want to weight them.
            </Text>
            <Text> Add your own using the blue &quot;Suggest&quot; box</Text>
            <Text>
              <strong>First-timers might need to reload the page</strong> to interact.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <HStack backgroundColor='cyan.200'>
        <HStack>{sentence}</HStack>
        {/*         <RadioGroup
          sx={{ border: '1px solid lightgray', padding: '0px 4px' }}
          onChange={onChangeRadio}
          defaultValue={defaultDecision}
        >
          <HStack direction='row'>
            <Radio bg='#fff' value='Questions/3495f3bd-4c05-4dce-80c7-26dd9c91f9d2'>
              Name It
            </Radio>
            <Radio bg='#fff' value='Questions/5395cb6c-c2f6-4794-96b1-06464a94da87'>
              What is it?
            </Radio>
            <Radio bg='#fff' value='Questions/d8d8f9d7-b1e9-420b-aa0c-c1ccb62d6ecb'>
              What it does
            </Radio>
            <Radio bg='#fff' value='Questions/91da40a3-03db-48a1-a943-ca33a52f5e67'>
              Who benefits?
            </Radio>
            <Radio bg='#fff' value='Questions/eed624dc-c8bf-4b48-99f6-9f5ea5664c50'>
              What can they do?
            </Radio>
            <Radio bg='#fff' value='Questions/ba6ba03e-39c2-4d5d-a9d5-e541d4c5f1c3'>
              Important why?
            </Radio>
          </HStack>
        </RadioGroup> */}
      </HStack>
      <Accordion
        maxWidth={'500px'}
        backgroundColor={'#eee'}
        allowToggle
        /* width={width}
            onChange={onChange} */
      >
        <AccordionItem>
          <Heading as='h2'>
            <AccordionButton>
              <Text sx={{ fontWeight: 'bold', fontSize: '18px' }}>Current top version:</Text>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            <HStack>{currentSentence}</HStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <UserAuth unauthorized='signin'>
        {(!template || !sentence) && (
          <Link as={RouterLink} to={'/search_decisions'}>
            {'Go to Inquiries and choose your group, then click Positioning Exercise link'}
          </Link>
        )}
      </UserAuth>
    </Box>
  );
}

export default Positioning;
