import React, { useEffect, useMemo, useState } from 'react';
// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';
import { Box, Flex, Button, Heading, Link, Text, Switch, HStack } from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import QuestionsList from 'components/Decisions/QuestionsList';
import PollTable from 'components/Decisions/PollTable';
import OptionDetails from 'components/Decisions/OptionDetails';
import useDecisions from 'hooks/useDecisions';
import useGroups from 'hooks/useGroups';
import { NetworkBox } from 'components/Networks/NetworkTabs';

const OnePoll = props => {
  const { nooUser } = useNoo('OnePoll');
  const { group_in, decision_in, menu, loggedin } = props; // defaults to no decision selector. pass in truthy menu
  const [currentUser, setCurrentUser] = useState(loggedin);
  const { currentGroupId, setGroupId } = useGroups({
    from: 'OnePoll'
  });

  const {
    decisions,
    currentDecisionId,
    currentDecision,
    getDecisionsByCurrentGroup,
    getDecisionById,
    isActive
  } = useDecisions();

  const [fullScreen, setFullScreen] = useState(false);
  const toggleFull = () => {
    setFullScreen(!fullScreen);
  };

  useEffect(() => {
    if (loggedin != currentUser) {
      setCurrentUser(loggedin);
      getDecisionById(currentDecisionId);
    }
  }, [currentDecisionId, currentUser, getDecisionById, loggedin]);

  useEffect(() => {
    if (group_in != currentGroupId) {
      // console.log('setting group', group_in);
      setGroupId(group_in);
    }
  }, [currentGroupId, decisions, group_in, setGroupId]);

  // const currentGroupId = 'Groups/0c73e7c3-47d5-4fcf-9fa4-6048a3d99ef7';
  useEffect(() => {
    if (props.ready && currentGroupId && decision_in && currentDecisionId != decision_in) {
      getDecisionById(decision_in);
    }
  }, [
    currentDecisionId,
    currentGroupId,
    decision_in,
    decisions,
    getDecisionById,
    nooUser,
    props.ready
  ]);

  const upperRight = { top: 0, right: 0, position: 'initial' };
  const qdata = currentDecision?.question?.data || {};
  return (
    <Box>
      <Heading size={'md'}>{qdata.title}</Heading>
      <Text>{qdata.description}</Text>
      {props.ready && <PollTable data={qdata} />}
    </Box>
  );
};

export default OnePoll;
