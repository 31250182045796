import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Select } from '@chakra-ui/react';
import useGroups from 'hooks/useGroups';
import useMultiDomain from 'hooks/useMultiDomain';
import useNoo from 'hooks/useNoo';
import useDecisions from 'hooks/useDecisions';
import { createTree, traverse_tree } from 'utils/treemapUtils';
import { objectSort } from 'utils';

const DecisionSelector = props => {
  const {
    // nooUser,
    callback,
    showAnyOption = false,
    showNoneOption = true,
    render = 'selector',
    selected,
    filters = ['all'],
    width = '300px',
    indentOptions = false,
    groupId
  } = props;

  const { currentGroupId, setGroupId, currentGroupName } = useGroups({
    filters,
    from: 'DecisionSelector'
  });
  const [lastGroupId, setLastGroupId] = useState(null);
  const { decisions, getDecisionsByCurrentGroup, isActive, decisionTree, paths } = useDecisions();
  const searchOnLoad = useRef(false);
  const hasSearchedOnce = useRef(false);

  useEffect(() => {
    if (groupId && currentGroupId != groupId) {
      setGroupId(groupId);
    }
  }, [currentGroupId, groupId, setGroupId]);

  useEffect(() => {
    if (!(decisions?.length > 0) && currentGroupId && currentGroupId != lastGroupId) {
      getDecisionsByCurrentGroup();
      setLastGroupId(currentGroupId);
    }
  }, [
    currentGroupId,
    getDecisionsByCurrentGroup,
    setLastGroupId,
    lastGroupId,
    decisions,
    decisionTree
  ]);

  const is_selector = true;
  const { groupSelector } = useMultiDomain();
  const { nooUser } = useNoo('DecisionSelector');

  const onSelectDecision = event => {
    const decision_id = event.target.value;
    if (callback) {
      callback(event, decision_id);
    }
  };

  const options_new = useMemo(() => {
    // Filter out positioning exercise trees
    if (decisions) {
      const to_hide = decisions
        .filter(one => {
          return (
            one.data.description ==
            'This is the parent decision for aggregating the inputs from madlibs.'
          );
        })
        .map(one => one._id);
      let opts = [];
      if (paths) {
        paths.sort();
        const tree = createTree(paths.filter(path => !to_hide.includes(path[0])));
        traverse_tree(tree, 0, '', (node, depth, path) => {
          let match = _.find(decisions, one => {
            return one._id == node._id;
          }) || { data: { title: currentGroupName } };
          const prefix = '   > '.repeat(depth > 0 ? depth - 1 : 0);
          const fullname = match.data?.title;
          const id = match._id;
          const turn_off = node._id == 'root'; // createTree adds a top level root // false; // disabled.includes(id);
          // const valid = id && (!rootId || id == rootId || path.indexOf(rootId) >= 0); // tbd complete path
          if (fullname && !turn_off) {
            opts.push(
              <option value={id} key={id}>
                {prefix + fullname}
              </option>
            );
          }
        });
      }
      opts.sort(objectSort('props.children'));
      if (opts?.length > 0) {
        if (showAnyOption) {
          opts.unshift(
            <option value='' key='decision-any-option'>
              Any
            </option>
          );
        }
        if (showNoneOption) {
          opts.unshift(
            <option value='' key='decision-any-option'>
              None
            </option>
          );
        }
        return opts;
      }
    }
  }, [currentGroupName, decisions, paths, showAnyOption, showNoneOption]);

  const options = useMemo(() => {
    let opts = [];
    if (is_selector && decisions?.length && !indentOptions) {
      opts = decisions
        .map(decision => {
          const prefix = decision.data.parent_id ? ' > ' : '';
          const fullname = decision.data?.title;
          const id = decision._id;
          const turn_off = false; // disabled.includes(id);
          // const valid = id && (!rootId || id == rootId || path.indexOf(rootId) >= 0); // tbd complete path
          if (fullname && !turn_off) {
            return (
              <option value={id} key={id}>
                {prefix + fullname}
              </option>
            );
          }
          return null;
        })
        .filter(decision => !!decision);
    }
    if (opts?.length > 0) {
      if (showAnyOption) {
        opts.unshift(
          <option value='' key='decision-any-option'>
            Any
          </option>
        );
      }
      if (showNoneOption) {
        opts.unshift(
          <option value='' key='decision-any-option'>
            None
          </option>
        );
      }
      return opts;
    }

    return null;
  }, [is_selector, indentOptions, showAnyOption, showNoneOption, decisions]);

  return (
    <div>
      {is_selector && (
        <Select
          onChange={onSelectDecision}
          // onBlur={onBlurGroup}
          value={selected || ''}
          w={width}
          bg='#fff'
          _focus={{ outline: 0 }}
        >
          {options_new}
        </Select>
      )}
    </div>
  );
};

export default DecisionSelector;
