import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  RadioGroup,
  Radio,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import OnePoll from 'components/Decisions/OnePoll';

const PollAgenda = props => {
  const [tab, setTab] = useState(0);
  const defaultDecision = 'Questions/7869d342-7b59-4af1-a298-451c9a9ad2e0';
  const defaultGroup = 'Groups/9baab5f4-ab3a-4814-92fc-711ff2112a0c';
  const [decision, setDecision] = useState(defaultDecision);
  const saveTab = t => {
    setTab(t);
  };
  const items = props.polls.map((one, index) => {
    return (
      <Radio bg='#fff' key={index} value={one.qid}>
        {one.title}
      </Radio>
    );
  });

  const tabs = props.polls.map((one, index) => {
    return <Tab key={index}>{one.title}</Tab>;
  });

  const isReady = index => {
    return tab == index;
  };

  const panels = props.polls.map((one, index) => {
    return (
      <TabPanel key={index}>
        <OnePoll group_in={defaultGroup} decision_in={one.qid} ready={isReady(index)} />
      </TabPanel>
    );
  });

  return (
    <HStack backgroundColor='cyan.200'>
      <Tabs
        variant='enclosed-colored'
        colorScheme='green'
        onChange={saveTab}
        /* defaultIndex={default_tab} */
      >
        <TabList backgroundColor='#ffffff'>{tabs}</TabList>

        <TabPanels backgroundColor='#eee'>{panels}</TabPanels>
      </Tabs>
      {/*       <RadioGroup
        sx={{
          border: '1px solid lightgray',
          padding: '0px 4px',
          fontWeight: 'bold'
        }}
        onChange={props.onChangeDecision}
        // defaultValue={defaultDecision}
        size={'lg'}
      >
        <HStack direction='row'>{items}</HStack>
      </RadioGroup> */}
    </HStack>
  );
};
export default PollAgenda;
