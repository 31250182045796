import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
  Button,
  Box,
  Text,
  HStack
} from '@chakra-ui/react';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import OneDecision from 'components/Decisions/OneDecision';

import _ from 'lodash';
import { inputs } from '@theme-ui/preset-tailwind';

const DecisionModal = props => {
  const { decisionId, groupId, prefix, decisionData, label } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inEdges, setInEdges] = useState(null);
  const [respectEdges, setRespectEdges] = useState(null);
  const [message, setMessage] = useState('');
  // const dispatch = useDispatch();
  const { nooUser } = useNoo('DecisionModal');
  const { nooCommandDirect } = useNooApi();
  const valid_marker = (nooUser?.claimed || []).length > 1; // (nooUser?.valids || []).length > 0 &&
  const disabled = !valid_marker;

  const defaultDecision = decisionId;
  const defaultGroup = groupId;
  const [decision, setDecision] = useState(defaultDecision);

  const firstOpen = () => {
    onOpen();
  };

  const endorsements = useMemo(() => {
    if (inEdges) {
      let out = {};
      inEdges.forEach(one => {
        if (one.data) {
          out[one._from] = one.data;
        }
      });
      return out;
    }
    return null;
  }, [inEdges]);

  const updateTags = data => {
    setRespectEdges(data);
  };

  useEffect(() => {
    let msg = '';
    if (!valid_marker)
      msg += 'You are not yet authorized because you have not yet claimed at least two accounts';
    if (!valid_marker) {
      msg = 'Sorry. ' + msg;
    }
    setMessage(msg);
  }, [valid_marker]);

  const onSubmit = e => {
    onClose();
  };
  const title = decisionData.data.title;
  return (
    <Box display='inline'>
      <Tooltip label={title}>
        <Text sx={{ display: 'inline' }} onClick={onOpen} bgColor={'white'}>
          {label || title}
        </Text>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{''}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OneDecision group_in={defaultGroup} decision_in={decision} fullscreen={true} />
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DecisionModal;
