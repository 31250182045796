import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Flex,
  Box,
  VStack,
  Button,
  Input,
  FormLabel,
  Spinner,
  Textarea,
  Heading
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import UserAuth from 'components/User/UserAuth';
import formatResponse from 'utils/formatResponse';
import Results from '../Search/Results/Groups';
import GroupCreate from 'components/Segments/GroupCreate';
// import DecisionCreateDialog from 'components/Segments/DecisionCreateDialog';
import DecisionCreateModal from 'components/Segments/DecisionCreateModal';
import GroupAddUserDialog from 'components/Segments/GroupAddUserDialog';
import EdgeCreate from 'components/Segments/EdgeCreate';
import EdgeUpdate from 'components/Segments/EdgeUpdate';
import InputUser from 'components/Invitations/InputUser';

const Admin = () => {
  const { googleUser: user, isAuthorized, nooUser } = useNoo('Admin');
  const dispatch = useDispatch();
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('{}');
  const [aqlQuery, setAqlQuery] = useState('');
  const [responseHeight, setResponseHeight] = useState('100px');
  const responseRef = useRef(null);
  const [groupId, setGroupId] = useState(null);
  const [inputs, setInputs] = useState({ group_id: groupId, prompt: 'Suggest' });
  const [edgeDir, setEdgeDir] = useState('INBOUND');
  const [edgeDepth, setEdgeDepth] = useState(2);

  useEffect(() => {
    const textAreaRef = responseRef.current;
    if (textAreaRef !== null) {
      const scrollHeight = textAreaRef.scrollHeight;
      if (scrollHeight > 100) {
        setResponseHeight(scrollHeight);
      }
    }
  }, [responseRef]);

  const userIdOf = nooU => {
    return nooU.person?._id || nooU.nodes[0]?.person?._id;
  };

  useEffect(() => {
    setLoading(nooCommandIsProcessing);
  }, [nooCommandIsProcessing]);

  useEffect(() => {
    // console.log('RESPONSE', nooCommandResponse, nooCommandResponse?.command in ['aql']);
    if (['aql'].includes(nooCommandResponse?.command)) {
      setResponse(nooCommandResponse.data);
    }
    // note: reason for this disable is the console.log() above
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nooCommandResponse?.command, nooCommandResponse?.data]);

  const onMyGroups = useCallback(() => {
    const person_id = userIdOf(nooUser);
    if (isAuthorized && person_id) {
      const payload = { query_name: 'aqlGroupsByPersonId', data: { person_id } };
      dispatch(nooCommand('aql', { payload }));
    }
  }, [dispatch, nooCommand, nooUser, isAuthorized]);

  useEffect(() => {
    const person_id = nooUser?.person?._id;
    if (isAuthorized && person_id) {
      onMyGroups();
    }
  }, [nooUser, dispatch, nooCommand, isAuthorized, onMyGroups]);

  const onCallTest = () => {
    dispatch(nooCommand('test', { foo: 'bar' }));
  };

  const onCallRead = () => {
    const payload = { query_name: 'aqlReadPerson', data: {} };
    dispatch(nooCommand('aql', { payload }));
  };

  const onCallReadFAN = () => {
    const payload = {
      query_name: 'aqlReadPerson',
      data: {},
      fanId: 'did:fan:a47091e0961ef88005b82181b7ef3104'
    };
    dispatch(nooCommand('aql', { payload }));
  };
  const onUpdateAqlQuery = e => {
    setAqlQuery(e.target.value);
  };
  const onCallAqlSearch = () => {
    const payload = { query_name: 'aqlFreeform', data: { query: aqlQuery } };
    dispatch(nooCommand('aql', { payload }));
  };

  const onUndoInvitation = () => {
    const payload = { query_name: 'aqlUndoInvitation', data: inputs };
    // dispatch(nooCommand('aql', { /*user, */ payload }));
  };

  const onCreateListing = e => {
    const test = {
      title: 'Loafer',
      description: 'listing description',
      zipCode: '95472',
      state: 'california',
      country: 'usa',
      webpage: 'www.something.com',
      features: {
        feature1: 'something',
        feature2: 'something else'
      }
    };
    const listing = { resource: { type: 'listing', data: test } };
    dispatch(nooCommand('createListing', { data: listing }));
  };

  const onCreateDocument = e => {
    const cloud = {
      'active inference': {
        people: ['b92033d6-6b75-41f8-bd41-10009f69b9a9'],
        total: 0.26525198938992045
      },
      analogies: { people: ['b92033d6-6b75-41f8-bd41-10009f69b9a9'], total: 0.46949602122015915 },
      arangodb: { people: ['3df962d2-9570-4602-9904-484af2ae99d0'], total: 0.18796992481203006 },
      'big picture': { people: ['80e47466-3a68-4090-be7c-70aae33a486e'], total: 0.5 },
      cointelligence: {
        people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'],
        total: 0.17094806874884494
      },
      'collective wisdom ': {
        people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'],
        total: 0.3567732396969137
      },
      'common sense': {
        people: ['3df962d2-9570-4602-9904-484af2ae99d0'],
        total: 0.18796992481203006
      },
      communication: {
        people: ['ef308b55-f395-4f98-bc59-a6dea5c10408'],
        total: 0.26737967914438504
      },
      compassion: { people: ['f66eed61-bf82-41e0-a0c7-5e9c680fd5eb'], total: 1 },
      'complex systems': { people: ['80e47466-3a68-4090-be7c-70aae33a486e'], total: 0.5 },
      daos: { people: ['3df962d2-9570-4602-9904-484af2ae99d0'], total: 0.18796992481203006 },
      data: { people: ['b7b5897d-fca7-4d06-bfba-63994479be0e'], total: 0.5 },
      dj: { people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'], total: 0 },
      dweb: { people: ['6f501bc2-c344-4b5f-977d-9e5c1169f0af'], total: 0.13850415512465372 },
      'future women': { people: ['781dbab0-92cb-42c3-9bc6-feecf4e9f3a8'], total: 0.5 },
      'gardening ': { people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'], total: 0.3567732396969137 },
      humor: { people: ['b92033d6-6b75-41f8-bd41-10009f69b9a9'], total: 0.26525198938992045 },
      identity: {
        people: ['3a18346b-3ebf-4ede-89da-4f53eb3e9084', '6f501bc2-c344-4b5f-977d-9e5c1169f0af'],
        total: 0.8614958448753463
      },
      leadership: {
        people: ['ab79e334-e40c-4743-b9fb-8e12c324ea94', 'ef308b55-f395-4f98-bc59-a6dea5c10408'],
        total: 0.767379679144385
      },
      'machine learning': {
        people: ['f27c21b8-8ae6-4614-98d8-e38ebbb9fce3'],
        total: 0.13371844244758238
      },
      music: { people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'], total: 0.05775272592866383 },
      'network weaving': { people: ['e384cde6-154a-400f-b6a8-787738d17fc6'], total: 1 },
      'open source': { people: ['b7b5897d-fca7-4d06-bfba-63994479be0e'], total: 0.5 },
      'open source attention': {
        people: ['f27c21b8-8ae6-4614-98d8-e38ebbb9fce3'],
        total: 0.42233632862644416
      },
      organization: { people: ['ef308b55-f395-4f98-bc59-a6dea5c10408'], total: 0.4652406417112299 },
      people: { people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'], total: 0.05775272592866383 },
      python: { people: ['f27c21b8-8ae6-4614-98d8-e38ebbb9fce3'], total: 0.13371844244758238 },
      regen: { people: ['52c61bd5-4758-456a-8dcc-7b8c5619d671'], total: 0.26737967914438504 },
      regeneration: {
        people: ['52c61bd5-4758-456a-8dcc-7b8c5619d671'],
        total: 0.26737967914438504
      },
      research: { people: ['f27c21b8-8ae6-4614-98d8-e38ebbb9fce3'], total: 0.17650834403080873 },
      'self-sovereign': {
        people: ['6f501bc2-c344-4b5f-977d-9e5c1169f0af'],
        total: 0.13850415512465372
      },
      'tech for good': {
        people: ['52c61bd5-4758-456a-8dcc-7b8c5619d671'],
        total: 0.4652406417112299
      },
      thrivability: { people: ['781dbab0-92cb-42c3-9bc6-feecf4e9f3a8'], total: 0.5 },
      vision: {
        people: [
          '3df962d2-9570-4602-9904-484af2ae99d0',
          'ab79e334-e40c-4743-b9fb-8e12c324ea94',
          '6f501bc2-c344-4b5f-977d-9e5c1169f0af'
        ],
        total: 1.297586070439256
      },
      web3: { people: ['f27c21b8-8ae6-4614-98d8-e38ebbb9fce3'], total: 0.13371844244758238 },
      wisdom: { people: ['e3ad9e2c-40ec-4a89-afc8-b7fec35af6cf'], total: 0 }
    };
    // using 'document' type until 'file' is added to protobuf, requires url
    const data0 = {
      url: 'file://tag_cloud_people',
      filename: 'tag_cloud_people',
      abstract: 'Tags used in people marks',
      cloud
    };
    const data = {
      url: 'file://popular_people_tags',
      filename: 'popular_people_tags',
      abstract: 'Word cloud of tags used to mark people, with usage count keyed by tag',
      cloud: { foo: 17 }
    };
    const doc_data = { resource: { type: 'document', data } };
    console.log('COMMENTED OUT, doc_data', doc_data);
    // dispatch(nooCommand('createDocument', { data: doc_data }));
  };

  const updateInputs = e => {
    const myInputs = { ...inputs };
    myInputs[e.target.name] = e.target.value;
    setInputs(myInputs);
  };
  const onEdgeDir = e => {
    setEdgeDir(e.target.value);
  };
  const onEdgeDepth = e => {
    setEdgeDepth(e.target.value);
  };
  const onGetEdges = () => {
    const user_id = userIdOf(nooUser);
    const payload = {
      query_name: 'aqlQueryPathsToOrFromUser',
      data: { user_id, depth: edgeDepth, direction: edgeDir }
    };
    dispatch(nooCommand('aql', { payload }));
  };

  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box className='App-card'>
          <Heading as='h1'>{`Admin Panel - Logged in as: ${user?.displayName}`}</Heading>
          <Box>
            <Button onClick={onCallTest} disabled={loading}>
              {'Test'}
            </Button>
            <Button onClick={onCallRead} disabled={loading}>
              {'Read Self'}
            </Button>
            <Button onClick={onCallReadFAN} disabled={loading}>
              {'Read FAN Self'}
            </Button>
            <Button onClick={onMyGroups} disabled={loading}>
              {'My Groups'}
            </Button>
            <Button onClick={onCreateListing} disabled={loading}>
              {'Create Listing'}
            </Button>
            <Button onClick={onCreateDocument} disabled={loading}>
              {'Create Document (tag cloud)'}
            </Button>
          </Box>
          <VStack>
            <Heading as='h4' sz='sm'>
              Test Import User
            </Heading>
            <InputUser />
          </VStack>
          <Flex
            sx={{
              backgroundColor: '#eee',
              marginTop: '10px',
              padding: '10px',
              border: '1px solid #444'
            }}
          >
            <Heading as='h4'>Get Edges</Heading>
            <Box>
              <Input
                sx={{ height: '30px', width: '70px', padding: '1px' }}
                type='text'
                id='depth'
                name='depth'
                placeholder='DEPTH >= 1'
                onBlur={onEdgeDepth}
              />
            </Box>
            <Box>
              <Input
                sx={{ height: '30px', padding: '1px' }}
                type='text'
                id='direction'
                name='direction'
                placeholder='INBOUND, OUTBOUND, or ANY'
                onBlur={onEdgeDir}
              />
            </Box>
            <Box sx={{ width: '20%' }}>
              <Button onClick={onGetEdges} disabled={loading}>
                {'My Edges'}
              </Button>
            </Box>
          </Flex>

          <FormLabel htmlFor='aql'>AQL query:</FormLabel>
          <textarea type='text' id='aql' name='aql' value={aqlQuery} onChange={onUpdateAqlQuery} />
          <Button onClick={onCallAqlSearch} disabled={loading || aqlQuery === ''}>
            {'AQL Search'}
          </Button>

          <EdgeCreate />
          <EdgeUpdate />

          <DecisionCreateModal />
          <GroupAddUserDialog />
          <GroupCreate />

          <Box>
            <Results className='groups' data={response} />
          </Box>
        </Box>

        {nooCommandIsProcessing && <Spinner />}
        <FormLabel htmlFor='grpcResponse'>GRPC Response:</FormLabel>
        <Textarea
          ref={responseRef}
          id='grpcResponse'
          name='grpcResponse'
          style={{ height: responseHeight }}
          readOnly={true}
          value={formatResponse(response, false)}
        />
      </UserAuth>
    </Box>
  );
};

export default Admin;
