import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { LuCheckSquare, LuFolder, LuUser } from 'react-icons/lu';
import { ExternalLinkIcon } from '@chakra-ui/icons';

// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Radio,
  RadioGroup,
  Button,
  useToast,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';

import useNoo from 'hooks/useNoo';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import useGroups from 'hooks/useGroups';
import UserAuth from 'components/User/UserAuth';
import CopyButton from 'components/Segments/CopyButton';

import MarkdownAccordion from 'components/Segments/MarkdownAccordion';
import markdown from 'shared/config/homepageMarkdowns/third';
import JsonAccordion from 'components/Segments/JsonAccordion';
import PollAgenda from 'components/Segments/PollAgenda';
import OnePoll from 'components/Decisions/OnePoll';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}
const tabSx = {
  border: '1px solid grey'
};

const Splash = () => {
  const { googleUser: user, currentNetworkData, nooUser } = useNoo('Splash');
  const network = currentNetworkData;

  const [focusRef, setFocusRef] = useState(null);
  const [warned, setWarned] = useState(false);
  const [inviter, setInviter] = useState(null);
  const [inviteGroup, setInviteGroup] = useState(null);
  const inputRef = useRef();
  const accordionRef = useRef();
  const history = useHistory();
  const toast = useToast();
  const [topIndex, setTopIndex] = useState(-1);
  const [innerIndex, setInnerIndex] = useState(-1);
  const [, forceUpdate] = useReducer(x => x + 1, 0); // https://stackoverflow.com/questions/46240647/react-how-to-force-to-re-render-a-functional-component/53837442#53837442

  // TODO: if using invitations here, update to useInvitations() hook
  // const onCallAcceptInvitation = () => {
  // 	nooApi.acceptInvitation(user, invitationId, setAcceptLoading, setAcceptResponse);
  // };

  let params = useQuery();
  const polls = [
    { qid: 'Questions/d5a80d72-545d-416a-96bf-054ceb119603', title: 'Economics' },
    { qid: 'Questions/b8d21250-8dfe-411e-8d10-0c71493c276c', title: 'Governance' },
    { qid: 'Questions/e0929649-196e-43bc-9eab-60d20e9e8fb3', title: 'Nature' },
    { qid: 'Questions/7c4fd326-9bf0-4d70-bf13-aeaeb979426b', title: 'Society' },
    { qid: 'Questions/ae3267e4-d606-45c6-9923-7863d232634c', title: 'Climate' },
    { qid: 'Questions/cb9fb450-3749-45d7-a3b4-0259f353f6c4', title: 'Science/Tech' }
  ];

  useEffect(() => {
    if (params.token) {
      const [group, inviter] = params.token.split('|');
      setInviter(inviter);
      setInviteGroup(group);
      // history.replace('/');
    }
  }, [history, inviter, params]);

  useEffect(() => {
    if (inviter && inviteGroup) {
      const key = 'inviters';
      let inviters_by_group = JSON.parse(localStorage.getItem(key) || '{}');
      let current = inviters_by_group[inviteGroup] || [];
      if (!current.includes(inviter)) {
        current.push(inviter);
      }
      inviters_by_group[inviteGroup] = current;
      localStorage.setItem(key, JSON.stringify(inviters_by_group));
    }
  }, [inviteGroup, inviter]);

  const makeLink = () => {
    if (nooUser?.person) {
      const gkey = defaultGroup.replace('Groups/', '');
      const ikey = nooUser.person._id.replace('Persons/', '');
      const token = [gkey, ikey].join('|');
      const link = location.href + '?token=' + token;
      toast({
        title: (
          <HStack>
            <Text>Share this link with others you want to invite</Text>{' '}
            <CopyButton text={link} color='#fff' bgColor='#f00' />
          </HStack>
        ),
        description: link,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    }
  };
  var invitationId = params.invite;
  const { currentGroupId, setGroupId, groupSelector, currentGroupName } = useGroups({
    from: 'Women splash'
  });
  const defaultDecision = 'Questions/7869d342-7b59-4af1-a298-451c9a9ad2e0';
  const defaultGroup = 'Groups/9baab5f4-ab3a-4814-92fc-711ff2112a0c';
  const [decision, setDecision] = useState(defaultDecision);

  // TBD: InputRef for each decision so we can focus there.

  const display = network?.display;
  const paras = network.welcome;

  const onChangeDecision = dId => {
    console.log('DID', dId);
    setDecision(dId);
    setTopIndex(0);
  };

  const boxSx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee',
    width: '75%'
  };
  const whiteBoxSx = {
    border: '1px solid #ccc',
    padding: '4px',
    margin: '4px',
    width: ['95%', '85%', '75%'],
    fontSize: ['12px', '14px', '16px']
  };

  const inquirySx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee'
  };
  const paraSx = {
    mb: '12px',
    fontSize: ['13px', '16px']
  };

  const crowdfund = 'https://opencollective.com/noonao/projects/planetary-women';
  // <Image src={'/public/images/ChildrenModestCover.jpg'} alt='' width={['120px']} />

  const closeAccordions = e => {
    console.log('TOP', e.target, e.target.parentNode);
    setInnerIndex(-1);
  };
  console.log('TOPp', topIndex, accordionRef.current);
  const toggle = e => {
    const ind = e.target.dataset.index;
    console.log('E', ind, e.target);
    setTopIndex(ind);
    // forceUpdate();
    // accordionRef.current.index = ind;
  };

  return (
    <Box>
      <VStack alignItems={'center'}>
        <Accordion allowToggle>
          <AccordionItem>
            <Heading as='h2'>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <Text fontSize='md' fontWeight={'bold'}>
                    About
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel pb={4}>
              <MarkdownAccordion
                markdown={markdown}
                sx={{ fontFamily: 'Helvetica', color: '#ff0000' }}
              />
              {/* <JsonAccordion /> */}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <PollAgenda polls={polls} onChangeDecision={onChangeDecision} />
        {/*         {decision && (
          <Box sx={inquirySx}>
            <OnePoll group_in={defaultGroup} decision_in={decision} />
          </Box>
        )} */}
      </VStack>
      <UserAuth unauthorized='signin'></UserAuth>
    </Box>
  );
};

export default Splash;
