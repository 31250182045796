import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { Box, Checkbox, Tooltip, Text } from '@chakra-ui/react';
import SortableTable from 'components/Segments/SortableTable';
import useSearchResults, { ResultLink } from 'hooks/useSearchResults';
import useDecisions from 'hooks/useDecisions';
import useNoo from 'hooks/useNoo';
import { AddOption } from 'components/Decisions/OptionsList';
import { VoteTotalBox } from './Styled';

import { objectSort, multiSort, truncate_precision, median } from 'utils';
import {
  getOptionTitle,
  // getOptionTallyType,
  getOptionTotalCharLength,
  addTalliesToOptions
} from 'utils/decisions';

const PollTable = props => {
  const kind = 'options';
  const { data, topic } = props;
  const { nooUser } = useNoo('PollTable');
  const [mockOptions, setMockOptions] = useState([
    { title: 'option1', description: 'this is', voters: [] },
    { title: 'option2', description: 'this is too ', voters: [] }
  ]);
  const [newOptionData, setNewOptionData] = useState({});
  const [maxAnswer, setMaxAnswer] = useState(1);
  const [avgAnswer, setAvgAnswer] = useState(1);
  const me = 'foo';
  const my_groups = useMemo(() => {
    return nooUser ? _.union(nooUser?.member, nooUser?.owner) : [];
  }, [nooUser]);

  const {
    currentDecision,
    currentOptionId,
    isActive,
    decisions,
    currentOption,
    addOptionError,
    getOptionById,
    getOptionVote,
    addAnswerToCurrentDecision,
    addOptionToCurrentDecision
  } = useDecisions();

  const {
    defaultColumnProperties,
    map_column_title,
    map_column_sx,
    get_columns
  } = useSearchResults(kind);

  const doCheckOption = useCallback(
    (index, is_checked) => {
      let now = new Date();
      const current = [...mockOptions];
      let which = current[index] || {};
      const voters = new Set(which.voters);
      if (is_checked) {
        voters.add(me);
      } else {
        voters.delete(me);
      }
      console.log('voters', voters);
      which.voters = voters;
      current[index] = which;
      setMockOptions(current);

      // onChecked(pid, is_checked, myChecked.length);
    },
    [mockOptions]
  );

  const optionRows = useMemo(() => {
    const onCheckOption = e => {
      const index = e.target.value;
      const is_checked = e.target.checked;
      doCheckOption(index, is_checked);
    };
    const handleCheckboxVote = (event, option) => {
      const vote = event.target.value > 0 ? 0 : avgAnswer; // binary on or off for checkbox click
      // console.log('check', vote, event.target.value, maxAnswer, avgAnswer);
      addAnswerToCurrentDecision({ optionId: option._id, vote });
    };
    let them = currentDecision?.options; // mockOptions
    if (them?.length > 0) {
      const rows = addTalliesToOptions(currentDecision, them);
      return rows.map((option, index) => {
        if (option) {
          const data = option.data; // option
          const title = data.title || data.label || data.prompt;
          const optionVote = getOptionVote({ decision: currentDecision, option }) / maxAnswer;
          const hasPositiveVote = optionVote > 0;
          const voters = Object.values(option.votes).filter(val => val > 0).length;
          return {
            titleSorter: title,
            title: <Tooltip label={data.description}>{title}</Tooltip>,
            description: data.description,
            score: (
              <VoteTotalBox maxChars={3} isHighlighted={hasPositiveVote}>
                {truncate_precision(option.total)}
              </VoteTotalBox>
            ), //maxCharsInOptionTotals
            scoreSorter: option.total,
            people: <Text>{voters}</Text>,
            peopleSorter: voters,
            checkbox: (
              <Checkbox
                // onChange={onCheckOption}
                onChange={event => handleCheckboxVote(event, option)}
                isChecked={hasPositiveVote}
                value={hasPositiveVote ? 1 : 0}
                sx={{ backgroundColor: 'white', borderColor: '#999' }}
                colorScheme='green'
                variant='solid'
                size='md'
                name={'options'}
                // defaultChecked={myChecked.includes(pid)}
                key={index}
              ></Checkbox>
            )
            // checkboxSorter = !option.is_checked;
          };
        }
      });
    } else {
      return [];
    }
  }, [
    addAnswerToCurrentDecision,
    avgAnswer,
    currentDecision,
    doCheckOption,
    getOptionVote,
    maxAnswer
  ]);

  const maxCharsInOptionTotals = useMemo(() => {
    if (optionRows?.length) {
      return getOptionTotalCharLength(optionRows.map(option => truncate_precision(option.total)));
    }
    return 1;
  }, [optionRows]);

  const cols = useMemo(() => {
    if (optionRows?.length) {
      return get_columns(optionRows[0]).map(key => {
        return {
          ...defaultColumnProperties,
          key: key,
          name: map_column_title(_.capitalize(key), kind),
          sx: map_column_sx(key, kind)
        };
      });
    }
    return [];
  }, [defaultColumnProperties, get_columns, optionRows, map_column_sx, map_column_title]);

  const addMockOptionToCurrentDecision = ({ label, description }) => {
    const current = [...mockOptions];
    const newOption = { title: label, description, created: new Date().toISOString(), voters: [] };
    current.push(newOption);
    setMockOptions(current);
  };
  const updateNewOptionData = e => {
    // console.log('updatenewoption', e.target.name, e.target.value, newOptionData);
    const myOptionData = { ...newOptionData };
    myOptionData[e.target.name] = e.target.value;
    setNewOptionData(myOptionData);
  };

  const handleAddOption = () => {
    let { label, description } = newOptionData;
    label = label ? label.trim() : '';
    description = description || null; // allow one space for no description? description.trim() : '';
    // description is not required
    if (label) {
      description = description || (confirm('Submit without a description?') ? '' : null);
      if (description != null) {
        addOptionToCurrentDecision({ label, description });
        // addMockOptionToCurrentDecision({ label, description });
        setNewOptionData({});
      }
    }
  };

  return (
    <Box mt='20px'>
      <AddOption
        disabled={!newOptionData.label}
        type={'option'}
        onChange={updateNewOptionData}
        onSubmit={handleAddOption}
        prompt={'Suggest something new'} /* data?.prompt || */
        label={newOptionData?.label}
        description={newOptionData?.description}
      />
      {optionRows?.length > 0 && (
        <SortableTable columns={cols} rows={optionRows} sortCol={0} sortDir={'desc'} />
      )}
    </Box>
  );
};

export default PollTable;
