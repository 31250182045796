import React, { useEffect, useMemo, useState, useRef, useReducer, useCallback } from 'react';
import { LuCheckSquare, LuFolder, LuUser } from 'react-icons/lu';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  makeFromToLink,
  makeShareLink,
  makeSolidLink,
  objectSort,
  copyToClipboard,
  decryptIt
} from 'utils';
import {
  _TOKEN_INVITE,
  makeSolidConnectionsKey,
  makeSolidUsernameKey
} from 'shared/constants/misc';

// import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Radio,
  RadioGroup,
  Button,
  useToast,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Tooltip,
  Input
} from '@chakra-ui/react';

import useNoo from 'hooks/useNoo';
import InvitationsAccept from 'components/Invitations/InvitationsAccept';
import useGroups from 'hooks/useGroups';
import UserAuth from 'components/User/UserAuth';
import AlertConfirm from 'components/Segments/AlertConfirm';
import GenericSelector from 'components/Segments/GenericSelector';
import CopyButton from 'components/Segments/CopyButton';
import SearchMinimal from 'components/Networks/SearchMinimal';
import markdown from 'shared/config/homepageMarkdowns/solidifi';
import markdown_instructions from 'shared/config/homepageMarkdowns/ppparty_instructions';
import MarkdownAccordion from 'components/Segments/MarkdownAccordion';
import InviteFromTo from 'components/Invitations/InviteFromTo';

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

function useQuery() {
  var params = new URLSearchParams(useLocation().search);
  var lookup = {};
  for (var pair of params.entries()) {
    lookup[pair[0]] = pair[1];
  }
  return lookup;
}
const tabSx = {
  border: '1px solid grey'
};

const Splash = () => {
  const { googleUser: user, currentNetworkData, nooUser } = useNoo('Splash');
  const network = currentNetworkData;
  const _CONNECTIONS_KEY = 'solid-connections-';

  const [focusRef, setFocusRef] = useState(null);
  const [warned, setWarned] = useState(false);
  const [liUser, setLiUser] = useState(null);
  const [connectionsDisplay, setConnectionsDisplay] = useState(null);
  const [invitePair, setInvitePair] = useState(null);
  const history = useHistory();
  const toast = useToast();
  const [gcId, setGcId] = useState(
    '3409f19e00da809808952a2c8bdf06020fa11ab4d51450bb60dbd052f2b518ef'
  );

  const [, forceUpdate] = useReducer(x => x + 1, 0); // https://stackoverflow.com/questions/46240647/react-how-to-force-to-re-render-a-functional-component/53837442#53837442

  const OneRow = props => {
    const { email, fullname, username } = props;
    return (
      <HStack>
        <Text>Invite {fullname} by </Text>
        <Button
          disabled={!(username && fullname)}
          data-email={email}
          data-username={username}
          data-fullname={fullname}
          onClick={sendLIMessage}
        >
          @{username}
        </Button>
      </HStack>
    );
  };

  const sendLIMessage = useCallback(
    async e => {
      const data = e.target.dataset;
      const username = data.username || data.linkedin;
      const fullname = data.fullname;
      let link = 'ff';
      await makeFromToLink(liUser, username).then(
        link => {
          copyToClipboard(
            'hi ' +
              fullname.split(' ')[0] +
              ",\n\n I'm using Solidifi to maintain a private list of allies. You're one of them.\n\n To make your own, click this: " +
              link
          );
          toast({
            title: (
              <VStack>
                <Text>
                  Your message and link are in your Copy/Paste buffer. Click below to go to{' '}
                  {fullname}
                  &apos;s Linkedin page, start a message, and paste there.
                </Text>{' '}
                <Link
                  sx={{
                    backgroundColor: 'red.400',
                    padding: '5px',
                    textColor: 'white',
                    rounded: 'lg'
                  }}
                  href={'https://linkedin.com/in/' + username}
                  isExternal
                >
                  Message them on Linkedin
                </Link>
              </VStack>
            ),
            description: '', // link
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        },
        function (error) {
          console.log('errr', error);
        }
      );
    },
    [liUser, toast]
  );
  useEffect(() => {
    const existing = localStorage.getItem(makeSolidUsernameKey());
    if (!liUser) {
      if (existing) {
        setLiUser(existing);
      }
    }
    const it = liUser || existing;
    if (it) {
      const connections = JSON.parse(
        localStorage.getItem(makeSolidConnectionsKey(it)) || '{"ins":{},"outs":{}}'
      );
      const fns = Object.values(connections.outs).map(one => one.fullname);
      const raw = fns.join(',') + '\n\n' + JSON.stringify(connections, null, 2);
      let them = Object.values(connections.outs).sort(objectSort('fullname'));
      const items = them.map(one => {
        const unm = one.username || one.linkedin;
        return (
          <HStack key={unm}>
            <OneRow username={unm} fullname={one.fullname} />
          </HStack>
        );
      });
      setConnectionsDisplay(items);
    }
  }, [liUser, sendLIMessage]);

  const _URL = new URL(location.href);
  var params = new URLSearchParams(_URL.search);

  const invite_blob_in = params.get(_TOKEN_INVITE);

  const decryptInvite = useCallback(
    async token => {
      if (invite_blob_in) {
        await decryptIt(invite_blob_in).then(function (response) {
          if (response.success) {
            const pair = response.token.split(',');
            setInvitePair(pair);
            console.log('set invite pair', pair);
          } else {
            console.log('Failed decrypting, error:', response.error);
            setInvitePair([]);
          }
        });
      }
    },
    [invite_blob_in]
  );

  useEffect(() => {
    if (invite_blob_in && !invitePair) {
      decryptInvite();
    }
  }, [decryptInvite, invitePair, invite_blob_in]);

  // TBD: InputRef for each decision so we can focus there.

  const display = network?.display;
  const paras = network.welcome;

  const boxSx = {
    border: '2px solid #aaa',
    padding: '4px',
    margin: '4px',
    backgroundColor: '#eee',
    width: '75%'
  };
  const whiteBoxSx = {
    border: '1px solid #ccc',
    padding: '4px',
    margin: '4px',
    width: ['95%', '85%', '75%'],
    fontSize: ['12px', '14px', '16px']
  };

  const textSx = { fontSize: ['14px', '18px'], fontFamily: 'bodoni' };

  const paraSx = {
    mb: '12px',
    fontSize: ['13px', '16px']
  };

  const usernameInput = e => {
    const inpt = e.target.value;
    if (inpt) {
      try {
        const uname = inpt.split('/in/')[1].split('?')[0];
        setLiUser(uname);
        localStorage.setItem(makeSolidUsernameKey(), uname);
        return;
      } catch (error) {
        console.log('Error getting username', error);
      }
    }
    console.log('failed username', inpt);
  };

  const resetConnections = useCallback(() => {
    if (confirm('Resetting connections for ' + liUser)) {
      // localStorage.removeItem(makeSolidUsernameKey());
      if (liUser) localStorage.removeItem(makeSolidConnectionsKey(liUser));
      setConnectionsDisplay(null);
    }
  }, [liUser]);

  const resetUsername = useCallback(() => {
    if (confirm('Clearing username ' + liUser)) {
      localStorage.removeItem(makeSolidUsernameKey());
      setLiUser(null);
    }
  }, [liUser]);

  return (
    <Box>
      <VStack alignItems={'center'}>
        {/* <Button sx={{ mr: '5px' }} bgColor={'#89df6f'}>
          Install the bookmarklet
        </Button> */}
        <Text sx={{ fontSize: '22px', fontWeight: 'bold', fontFamily: 'bodoni' }}>
          The Secret Solidarity Network
        </Text>
        <Text sx={{ fontSize: '18px', fontFamily: 'bodoni' }}>
          For coordinating and{' '}
          <Tooltip label='con-, meaning “together,” and spīrāre, “to breathe.”'>
            co-inspiring
          </Tooltip>{' '}
          without being a vector for surveillance.
        </Text>

        <Accordion
          className='accordion'
          /*  defaultIndex={topIndex} */
          allowToggle
          variant='subtle'
          width={['90vw', '82vw', '60vw']}
        >
          <AccordionItem>
            <AccordionButton data-index={1}>
              {' '}
              {/* onClick={toggle} */}
              <Heading as='h4' fontSize={'lg'}>
                Why Solidifi?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel sx={textSx}>
              <Text>
                <strong>
                  We need to put the{' '}
                  <Link
                    href='https://www.bbc.com/future/article/20190513-it-only-takes-35-of-people-to-change-the-world'
                    isExternal
                  >
                    3.5% rule
                  </Link>{' '}
                  to work NOW
                </strong>
                . Social graphs are the obvious way, but{' '}
                <strong>how to make them resistant to surveillance?</strong>
              </Text>
              <Text>
                We propose to do that by <strong>leveraging LinkedIn</strong>, making it easy for
                you to<strong> &quot;mark&quot; who you have solidarity with, privately</strong> to
                your local browser.
              </Text>
              <Text>
                3.5% of US adults = 4.2% of US LinkedIn users. We can span that in{' '}
                <strong>a few degrees of separation from 100 people</strong>.
              </Text>
              <Text>
                <strong>Mark your allies below to create your local solidarity network</strong>. It
                is stored private to your browser, and you can{' '}
                <strong>publish it anonymized to keep a count of our collective size</strong>.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton data-index={1}>
              {' '}
              {/* onClick={toggle} */}
              <Heading as='h4' fontSize={'lg'}>
                Claim your LinkedIn username. {liUser && `Done:`}
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel sx={textSx}>
              {liUser ? (
                <Text>You have claimed {liUser}</Text>
              ) : (
                <Box>
                  <Text>Enter your Linkedin profile URL </Text>
                  <Input name='url' onChange={usernameInput} />
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
          {liUser && (
            <AccordionItem>
              <AccordionButton data-index={1}>
                {' '}
                {/* onClick={toggle} */}
                <Heading as='h4' fontSize={'lg'}>
                  Your current connections
                </Heading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel sx={textSx}>
                <Box sx={boxSx}>
                  <Text>Invitations must be done one-to-one, via a message on Linkedin.</Text>
                  <Text>
                    Each button creates a unique invitation link from you to them, and loads it into
                    a standard message in your Paste Buffer.
                  </Text>
                  <Text>That link will add you as one of their incoming allies.</Text>
                </Box>
                <VStack alignItems={'left'}>{connectionsDisplay}</VStack>
              </AccordionPanel>
            </AccordionItem>
          )}

          {liUser && (
            <AccordionItem>
              <AccordionButton data-index={1}>
                {' '}
                {/* onClick={toggle} */}
                <Heading as='h4' fontSize={'lg'}>
                  Make connections
                </Heading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel sx={textSx}>
                <Link as={RouterLink} to={`/search_minimal?username=${liUser}`}>
                  Choose your allies
                </Link>
              </AccordionPanel>
            </AccordionItem>
          )}
          {liUser && (
            <AccordionItem>
              <AccordionButton data-index={1}>
                {' '}
                {/* onClick={toggle} */}
                <Heading as='h4' fontSize={'lg'}>
                  Manage your data
                </Heading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel sx={textSx}>
                <AlertConfirm
                  buttonText='Delete Connections'
                  description='delete your connections'
                  cb={resetConnections}
                />
                <AlertConfirm
                  buttonText='Clear Username'
                  description='clear your username'
                  cb={resetUsername}
                />
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </VStack>
      {/* {liUser && <SearchMinimal username={liUser} />} */}
      <UserAuth unauthorized='signin'></UserAuth>
    </Box>
  );
};

export default Splash;
