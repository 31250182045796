import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Input,
  FormLabel,
  Button,
  Flex,
  Link,
  Text,
  HStack,
  Textarea,
  VStack,
  useToast
} from '@chakra-ui/react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useNoo from 'hooks/useNoo';
import useNooApi from 'hooks/useNooApi';
import useInvitations from 'hooks/useInvitations';
import Select from 'react-select';
import GroupSelector from 'components/Segments/GroupSelector';
import { PopOverUser } from 'components/Segments/UserCard';
import { split_tags, makeFromToLink, objectSort, copyToClipboard } from 'utils';

const InviteFromTo = props => {
  const { personId } = props;
  const [localData, setLocalData] = useState({
    email: ''
  });
  const { createInvitation, invitationLoading } = useInvitations();
  const { isAuthorized, nooUser, googleUser: user } = useNoo('InviteFromTo');
  const { nooCommandDirect } = useNooApi();
  const [localErrors, setLocalErrors] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [existingInvitees, setExistingInvitees] = useState(null);
  const [knownInvitee, setKnownInvitee] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [emails, setEmails] = useState([]);
  const [rows, setRows] = useState([]);

  const toast = useToast();

  const exclude = (allm, excluded) => {
    return _.filter(allm, one => !_.includes(excluded, one));
  };

  useEffect(() => {
    const ready = localData?.email;
    setDisabled(ready ? false : true);
  }, [localData]);

  const onFieldUpdate = e => {
    const tgt = e.target;
    const field = tgt.name;
    const value = tgt.value;
    const trimmed = value.trim();
    setLocalErrors(null);
    const myLocalData = {
      ...localData
    };
    switch (field) {
      case 'phone':
        // console.log('setting', value, value.split('').reverse().join(''));
        break;
      case 'email':
        myLocalData[field] = trimmed;
        if (user.email == trimmed) {
          myLocalData.email = '';
          setLocalErrors(['You cannot invite yourself']);
        }
        const found = existingInvitees ? existingInvitees[trimmed] : null;
        setKnownInvitee(found);

        break;
      default:
        myLocalData[field] = trimmed;
        break;
    }
    setLocalData(myLocalData);
  };

  const onTags = e => {
    const myLocalData = {
      ...localData
    };
    myLocalData.tags = split_tags(e);
    setLocalData(myLocalData);
  };

  const fieldSx = useMemo(() => ({ mb: '0.5em' }), []);
  const sendEmailMessage = useCallback(
    async e => {
      console.log('target', e.target, e.target.dataset);
      const data = e.target.dataset;
      const email = data.email;
      let link = 'ff';
      await makeFromToLink('foo', email).then(
        link => {
          console.log('value', link);
          copyToClipboard(
            'hi ' +
              ", I'm using Solidifi to maintain a private list of allies. You're one of them. To make your own, click this: " +
              link
          );
          toast({
            title: (
              <VStack>
                <Text>
                  Your message and link are in your Copy/Paste buffer. Click to send a pre-formatted
                  image.
                </Text>{' '}
                <Link href={'mailto:' + email} isExternal>
                  Send email
                </Link>
              </VStack>
            ),
            description: '', // link
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        },
        function (error) {
          console.log('errr', error);
        }
      );
    },
    [toast]
  );

  const sendEmail = e => {
    const tgt = e.target;
    const email = tgt.dataset.email;
    const fn = tgt.dataset.fullname;
    if (email) {
      const subject = 'Invitation to the Bioregional Science Network';
      const message = `Hi ${fn}, \nI was asked by OneEarth.org to recommend scientists who have expertise in biology in specific bioregions and you came to mind.\n [link] `;
      window.open(
        `mailto:${email}?subject=${subject}&body=${encodeURIComponent(message)}`,
        '_blank'
      );
    }
  };

  const OneInvite = props => {
    const { email, fullname } = props;
    return (
      <HStack>
        {' '}
        {<FormLabel htmlFor='name'>Invite</FormLabel>}
        <Input
          type='text'
          placeholder='Gmail address for now'
          name='email'
          value={email}
          onChange={onFieldUpdate}
          // onBlur={onNameChange}
          sx={fieldSx}
        />
        <Button disabled={!(email && fullname)} data-email={localData?.email} onClick={sendEmail}>
          Invite by email
        </Button>
      </HStack>
    );
  };

  const OneRow = props => {
    const { email, fullname } = props;
    return (
      <HStack>
        <Text>Invite {fullname} by </Text>
        <Button
          disabled={!(email && fullname)}
          data-email={email}
          data-fullname={fullname}
          onClick={sendEmail}
        >
          email {email}
        </Button>
      </HStack>
    );
  };

  const mock =
    'Karl Burkart <karl@oneearth.org>, Margeigh Novotny <margeigh@gmail.com>, Julia Whitty <julia.whitty@gmail.com>, Dounia Saeme <dsaeme@gmail.com>';
  // Karl Burkart <karl@oneearth.org>, "a@b.com" <a@b.com>, Margeigh Novotny <margeigh@gmail.com>, Julia Whitty <julia.whitty@gmail.com>, Dounia Saeme <dsaeme@gmail.com>
  const makeRows = e => {
    console.log('val', e.target.value);
  };
  const updateList = e => {
    const fn_matcher = new RegExp('^(.*)<');
    const email_matcher = new RegExp('<(.*)>');
    const intext = e.target.value;
    const them = intext.split(',').map(one => one.trim());
    let out = [];
    them.forEach(one => {
      try {
        const fullname = fn_matcher.exec(one)[1].trim();
        const email = email_matcher.exec(one)[1].trim();
        console.log(fullname, email);
        out.push({ fullname, email });
      } catch (e) {
        console.error('Error parsing email input', one);
      }
    });
    let items = [];
    out.forEach(one => {
      items.push(<OneRow email={one.email} fullname={one.fullname} />);
    });
    setRows(items);
    setEmails(out);
  };
  const showtags = false;
  console.log('emails', emails);
  return (
    <Flex sx={{ w: '100%' }}>
      <Flex>
        <VStack>
          <FormLabel htmlFor='emails'>Paste emails copied from a recipient list:</FormLabel>
          <Textarea
            variant='outline'
            sx={{ backgroundColor: '#eee' }}
            type='text'
            id='emails'
            name='emails'
            rows='2'
            cols='60'
            onChange={updateList}
            placeholder='Joe Mama <joe@mama.com>, Woody Guthrie <woody@guthrie.com>'
          />
          <VStack alignItems={'left'}>{rows?.length > 0 && rows}</VStack>
        </VStack>
      </Flex>

      {localErrors &&
        localErrors.map((LE, idx) => {
          return (
            <Text key={idx} color='#dd0000' sx={{ m: 0, p: 0, color: '#dd0000' }}>
              {LE}
            </Text>
          );
        })}
    </Flex>
  );
};

export default InviteFromTo;
