import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  searchDecisionsByGroupId as _searchDecisionsByGroupId,
  getQuestionDataById,
  setCurrentOptionData,
  addOptionToDecision,
  addAnswerToDecision,
  createDecision,
  setAddOptionError,
  clearAllDecisions, // local data flush, not on server
  clearDecisionData // local data flush, not on server
  // clearDecisionsByGroup,
} from 'redux/actions/decisions';
import useNoo from './useNoo';
import useNooApi from './useNooApi';
import {
  graphFromTree,
  objectsFromPaths,
  nodesFromPaths,
  formatDataForTreemap,
  colorTree
} from 'utils/treemapUtils';

const getAnswerForVote = (answer = { data: { vote: {} } }, questionId, personId) => {
  // temp turn this off until we figure out how to vote with answer that has _id, create, modDate, etc.
  // return { ...answer, questionId, personId };
  // but keep any existing votes:
  return { data: { vote: { ...answer.data.vote } }, questionId, personId };
};

const useDecisions = () => {
  const { isAuthorized, personId } = useNoo();
  const { nooCommand } = useNooApi();
  const dispatch = useDispatch();
  const currentGroupId = useSelector(state => state.groups?.currentGroupId);
  const decisions = useSelector(state => state.decisions?.list);
  const paths = useSelector(state => state.decisions?.paths);
  const currentDecision = useSelector(state => state.decisions?.currentDecision, shallowEqual);
  const currentDecisionId = useSelector(state => state.decisions?.currentDecision?.question?._id);
  const currentDecisionError = useSelector(state => state.decisions?.currentDecisionError);
  const currentOption = useSelector(state => state.decisions?.currentOption);
  const currentOptionId = useSelector(state => state.decisions?.currentOption?._id);
  const isActive = useSelector(state => state.decisions?.isRequesting);
  const addOptionError = useSelector(state => state.decisions?.addOptionError);

  const clearDecisions = () => {
    dispatch(clearAllDecisions());
  };

  const clearCurrentDecision = () => {
    dispatch(clearDecisionData());
  };

  const getDecisionsByCurrentGroup = () => {
    console.log('GET DEC BY GID', currentGroupId);
    if (!isAuthorized) {
      console.warn('cannot hit api without isAuthorized');
      return;
    }
    console.log('ok, go find some decisions (current gid)', currentGroupId, !!isAuthorized);
    dispatch(_searchDecisionsByGroupId({ groupId: currentGroupId }));
  };

  const getDecisionsByGroupId = groupId => {
    if (!isAuthorized) {
      // console.warn('cannot hit api without isAuthorized');
      return;
    }
    if (!groupId) {
      // console.warn('getDecisionsByGroup, but no groupId passed. not performing decision search');
      return;
    }
    // console.log('ok, go find some decisions (by gid)', groupId, !!isAuthorized);
    dispatch(_searchDecisionsByGroupId({ groupId }));
  };

  const getDecisionById = questionId => {
    if (questionId) {
      dispatch(getQuestionDataById({ questionId, personId }));
    }
  };

  // currently we don't need to hit the DB for this, it's part of the decision structure...
  // just pass it into redux if it's found.
  const getOptionById = optionId => {
    if (optionId && currentDecision?.options?.length) {
      const option =
        currentDecision.options.find(option => option._id === optionId) ||
        currentDecision.options_other.find(option => option._id === optionId);
      if (option) {
        dispatch(setCurrentOptionData(option));
      }
    }
  };

  const addOptionToCurrentDecision = ({ label, description, data }) => {
    const questionId = currentDecision?.question._id;
    if (questionId) {
      return addOption({ questionId, label, description, data });
    }
    console.warn('addOptionToCurrentDecision called, but no currentDecision!');
  };

  const addOption = ({ questionId, label, description, data }) => {
    return dispatch(addOptionToDecision({ questionId, label, description, data }));
  };

  const addAnswerToCurrentDecision = ({ optionId, vote }) => {
    const questionId = currentDecision?.question._id;
    return addAnswer({ questionId, optionId, vote });
  };

  const addAnswer = ({ questionId, optionId, vote }) => {
    return dispatch(addAnswerToDecision({ questionId, optionId, vote }));
  };

  const createNewDecision = data => {
    console.log('createNewDecision', data);
    return dispatch(createDecision({ data }));
  };
  // const voteForOption = ({ vote, decision, option }) => {
  //   if (!personId) {
  //     console.warn('cannot vote for an option without a personId, abort');
  //     return;
  //   }
  //   const { _id: questionId } = decision.question;
  //   const { _id: optionId } = option;
  //   // TODO: figure out this structure better, alot of churn here on creation:
  //   const answer = getAnswerForVote(decision.answers?.[0], questionId, personId);
  //   // answer.data = answer.data || { vote: {} };
  //   // if (!answer.data.vote) {
  //   //   answer.data.vote = {};
  //   // }
  //   answer.data.vote[optionId] = vote;
  //   dispatch(nooCommand('createAnswer', { question_id: questionId, answer }));
  // };

  const getOptionVote = ({ decision, option }) => {
    if (decision && option) {
      const { _id: optionId } = option;
      const answerSet = decision.my_answer?.[0] || null;
      // console.log('getOptionVote, ', { optionId, answerSet, decision });
      return answerSet?.data?.vote?.[optionId];
    }
    return null;
  };

  const decisionTree = () => {
    let nodesData = {};

    if (currentDecision) {
      const paths = currentDecision.paths; // decision tree paths thru this decision
      const questions = currentDecision.questions;
      const options = currentDecision.options;
      const options_other = currentDecision.options_other;
      const option_scores = currentDecision.option_scores;
      const options_by_question = currentDecision.options_by_question;
      // get tree of ids from paths
      const tree = objectsFromPaths(paths);
      // get node data for each id
      const treeNodes = nodesFromPaths(
        paths,
        questions,
        options,
        options_other,
        option_scores,
        options_by_question
      );
      const noodes = { ...nodesData.nodes };
      let changed = false;
      let added = 0;
      Object.entries(treeNodes.nodes).forEach(([idd, values]) => {
        if (!(idd in noodes)) {
          noodes[idd] = values;
          added += 1;
          changed = true;
        }
      });
      const lookup = { ...nodesData.lookup };
      Object.entries(treeNodes.lookup).forEach(([idd, values]) => {
        if (!(idd in lookup)) {
          lookup[idd] = values;
          if (typeof values == 'string') lookup[values] = idd;
          added += 1;
          changed = true;
        }
      });
      if (changed) nodesData = { nodes: noodes, lookup };
      // format for any expectations
      formatDataForTreemap(tree, treeNodes);
      colorTree(tree);
      return tree[0];
    }
  };

  return {
    decisions,
    paths,
    currentDecision,
    currentDecisionId,
    currentOption,
    currentOptionId,
    currentDecisionError,
    isActive,
    addOptionError,
    decisionTree,
    clearDecisions,
    clearCurrentDecision,
    getDecisionsByCurrentGroup,
    getDecisionsByGroupId,
    getDecisionById,
    getOptionById,
    addOptionToCurrentDecision,
    addOption,
    addAnswerToCurrentDecision,
    addAnswer,
    createNewDecision,
    getOptionVote,
    setAddOptionError
    // voteForOption
  };
};

export default useDecisions;
