import React, { useState, useEffect, useRef, useCallback } from 'react';
import useNoo from 'hooks/useNoo';
import { useDispatch } from 'react-redux';
import GroupSelector from 'components/Segments/GroupSelector';
import PeopleSelector from 'components/Segments/PeopleSelector';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Flex, Box, Input, Textarea, Button, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useNooApi from 'hooks/useNooApi';
import useGroups from 'hooks/useGroups';
import UserAuth from 'components/User/UserAuth';
import useDecisions from 'hooks/useDecisions';

const GroupAddUserDialog = props => {
  const { group_id, parent_id, title, suggestion, description, type } = props;
  const label = props.label || 'Add user to group';
  const { currentGroupId } = useGroups();
  const [groupId, setGroupId] = useState(group_id || currentGroupId);
  const [first, setFirst] = useState(true);
  const [inputs, setInputs] = useState({
    title,
    description: description || 'All about ' + title,
    prompt: suggestion,
    group_id
  });
  const [personId, setPersonId] = useState(parent_id);

  const { nooUser } = useNoo('GroupAddUserDialog');
  const {
    nooCommand,
    nooCommandResponse,
    nooCommandResponseError,
    nooCommandIsProcessing
  } = useNooApi();
  const dispatch = useDispatch();

  const updateInputs = e => {
    const myInputs = { ...inputs };
    myInputs[e.target.name] = e.target.value;
    setInputs(myInputs);
  };

  const onSelectPerson = payload => {
    console.log('person', payload);
    setPersonId(payload._id);
  };

  const onSelectGroup = event => {
    const gid = event.target.value;
    if (gid != currentGroupId) {
      console.log('selected', gid);
      setGroupId(event.target.value);
    }
    // alert('Need to implement changing group')
  };

  const onAddUser = e => {
    const sourceId = groupId;
    const targetId = personId;
    const type = 'HAS_MEMBER';
    if (sourceId && targetId && type) {
      console.log('making edge ', sourceId, targetId, 'type', type);
      const payload = {
        from_id: sourceId,
        to_id: targetId,
        type: type,
        target_data: {}
        // edge_data: inputs.data
      };
      console.log('payload', payload);
      dispatch(nooCommand('createEdge', payload));
    } else {
      console.log('INCOMPLETE', sourceId, targetId, inputs.type);
    }
  };

  let disabled = groupId && personId ? false : true;

  const boxSx = {
    borderBottom: '1px solid #ccc',
    flex: '1 1 auto',
    paddingBottom: '4px'
  };
  return (
    <Box>
      <UserAuth unauthorized='signin'>
        <Box
          sx={{
            backgroundColor: '#eee',
            marginTop: '10px',
            padding: '10px',
            border: '1px solid #444'
          }}
        >
          <Heading as='h4' fontSize={'20px'}>
            {label}
          </Heading>
          <Flex sx={boxSx}>
            <Text as='i'>Group</Text>
            <GroupSelector
              callback={onSelectGroup}
              label={'Choose Group'}
              selected={groupId}
              render={'selector'}
              nooUser={nooUser}
              filters={['owner']}
            />
          </Flex>
          <Flex sx={boxSx}>
            <Text as='i'>User</Text>
            {groupId && (
              <PeopleSelector
                onSelect={onSelectPerson}
                inputPlaceholder={'Name as you know them...'}
                label={'Find someone to add'}
              />
            )}
          </Flex>

          <Box>
            <Button
              borderRadius='full'
              sx={{ height: '30px', padding: '3px' }}
              onClick={onAddUser}
              disabled={disabled}
            >
              {'Submit'}
            </Button>
          </Box>
        </Box>
      </UserAuth>
    </Box>
  );
};

export default GroupAddUserDialog;
