import React from 'react';
import { Avatar, Link } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { PlusSquareIcon } from '@chakra-ui/icons';
import FontAwesomeIcon from 'utils/FontAwesomeLoader';

const NetIcon = FontAwesomeIcon({
  icon: ['fad', 'chart-network'],
  name: 'share',
  style: { marginTop: '2px', marginInlineEnd: 0 }
});

const useSearchResults = kind => {
  const lookup = {
    decisions: {
      title: 'Issues',
      description_sx: {
        fontStyle: 'italic'
      }
    },
    options: {
      title: 'Principle',
      description_sx: {
        fontStyle: 'italic'
      },
      score: 'Score',
      score_sx: {
        width: '50px'
      },
      people: <Avatar size='xs' />,
      people_sx: {
        width: '50px'
      },
      checkbox: <PlusSquareIcon />,
      checkbox_sx: {
        width: '30px'
      }
    },
    groups: {
      title: 'Group',
      description: 'Description',
      description_sx: {
        fontStyle: 'italic'
      },
      invite: 'Invite',
      invite_sx: {
        width: '120px'
      },
      post: 'Post',
      post_sx: {
        width: '120px'
      }
    },
    listings: {
      title: 'Listing',
      host: 'Host',
      description: 'Description',
      edit: 'Edit'
    },
    profiles: {
      handle: '@LinkedIn',
      fullname_link: 'Name',
      description: 'Description',
      location: 'Location',
      pic: 'Avatar',
      in_count: NetIcon,
      checkbox: <PlusSquareIcon />
    },
    profiles_twitter: {
      handle: '@LinkedIn',
      fullname_link: 'Name',
      description: 'Description',
      pic: 'Avatar',
      in_count: 'Followers',
      checkbox: <PlusSquareIcon />
    },
    friends: {
      promote: 'promote',
      count: 'mutuals',
      username: '@Name',
      fullname: 'Name',
      img: 'Avatar'
    },
    headlines: {
      title: 'Headline',
      title_sx: {
        width: '320px'
      },
      count: '\u2605',
      image: '',
      ts: '\u25F4',
      ts_sx: {
        width: '30px'
      },
      post: 'Best Of'
    },
    documents: {
      title: 'Bookmark',
      title_sx: {
        width: '70%'
      },
      count: '\u2605',
      image: '',
      image_sx: {
        width: ['30%', '20%', '20%']
      },
      ts: '\u25F4',
      ts_sx: {
        width: '30px'
      },
      post: 'Best Of',
      tags: 'Tags'
    },
    edges: {
      from: 'Source',
      from_sx: {
        width: '20%'
      },
      ts: '\u25F4',
      ts_sx: {
        width: '30px'
      },
      to: 'Target',
      type: 'Action' //, pic:''
    }
  };

  const defaultColumnProperties = {
    sortable: true
  };

  const map_column_title = (column_title, kind) => {
    return column_title ? lookup[kind]?.[column_title.toLowerCase()] || column_title : column_title;
  };

  const map_column_sx = (column_title, kind) => {
    return column_title ? lookup[kind]?.[`${column_title}_sx`.toLowerCase()] : {};
  };

  const get_columns_by_kind = (kind, sample) => {
    switch (kind) {
      case 'decisions':
        return ['title', 'description'];
      case 'options':
        return ['score', 'people', 'checkbox', 'title']; // , 'description'
      case 'profiles':
        if (sample?.email) {
          return ['handle', 'email'];
        }
        return ['checkbox', 'in_count', 'pic', 'fullname_link', 'location', 'description']; // 'handle',
      case 'profiles_twitter':
        if (sample?.email) {
          return ['handle', 'email'];
        }
        return ['checkbox', 'in_count', 'pic', 'fullname_link', 'description']; // 'handle',
      case 'friends':
        return ['promote', 'count', 'img', 'fullname'];

      case 'listings':
        // return ['title', 'host', 'description', 'edit'];
        return ['icon', 'title', 'description', 'edit'];

      case 'groups':
        return ['title', 'description', 'invite', 'post']; // , 'post' // add back to put Post link in table

      case 'headlines':
        return ['count', 'image', 'ts', 'title']; // , 'post' // add back to put Post link in table

      case 'documents':
        return ['rate', 'image', 'ts', 'names', 'tags', 'title']; // , 'post' // add back to put Post link in table

      case 'edges':
        return ['ts', 'from', 'type', 'to']; // Alice praised Bob on 2/4/23 // 'pic',

      default:
        return [];
    }
  };

  /* rowGetter={i => rows[i]}
  rowsCount={_.size(row)}
  rows={rows}
  toolbar={<Toolbar enableFilter={true} />}
  ,
	  filterable: true
  */

  return {
    defaultColumnProperties,

    map_column_title: column_title => map_column_title(column_title, kind),
    map_column_sx: column_title => map_column_sx(column_title, kind),
    get_columns: sample => get_columns_by_kind(kind, sample)
  };
};

export default useSearchResults;

export const ResultLink = styled(Link)`
  display: inline-block;
  line-height: 1em;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
