import React, { useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure
} from '@chakra-ui/react';
const AlertConfirm = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const description = props.description;

  const confirmWithCB = () => {
    onClose();
    if (props.cb) props.cb();
  };

  return (
    <>
      <Button
        size='sm'
        ml='5px'
        colorScheme='red'
        rounded='2xl'
        onClick={onOpen}
        fontFamily={'sans-serif'}
      >
        {props.buttonText || 'Click me'}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {props.buttonText || 'Click me'}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure{description ? ' you want to ' + description + '.' : '?'} You can&apos;t
              undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={confirmWithCB} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AlertConfirm;
