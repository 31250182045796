const markdown = `
### What future do we want?
>  "The best way to invent the future is to envision it" - paraphrasing Alan Kay

Humanity and the planet are in the era of Metacrisis, and on track to one or both of two "attractors": **dystopian chaos** and **techno-authoritarianism**.

Much of that will be unavoidable, BUT we can create a **third attractor** to offer humanity a "less bad" future, a "transformation of common sense".

We invite you to help do that.

### A Mission
The purpose of this initiative is to:

*  **collectively articulate a clear concise vision** for such a third attractor;
*  ratify it by a **provably large number of planetary citizens**;
*  help that citizenry self-organize at planetary scale using **digital networks of trust**;
*  demonstrate **multi-scale governance** from local to planetary, within and above existing systems;
*  **offer humanity a future worth working toward** together, and **collective agency for realizing it**.

That may seem daunting, but we contend **those four steps are within our reach** (see Strategy and Achievable Outcomes below.) 

The unknown is **whether humanity will take if from there**.



### A Strategy
The overall strategy is to:

1.  clearly and **specifically articulate what such a third attractor would look like**; 
2. do that collaboratively with an **expanding network of participants** grown by invitation, and **dense with trust**; and 
3. form **a cultural, financial, and political force** from that network with a **provably large number of planetary citizens behind it**.



In more detail:

* **Collaboratively develop a concise description** that clearly specifies in detail the **positive alternative future** envisioned.
* Start that process with **a small number of initial participants**, each of whom **invites others to contribute**.
* Work with existing  **"islands of coherence"**.
* Facilitate that invitation process to **leverage Linkedin and GreenCheck/FAN**. This makes it easy to propagate and associates a ***proof of uniqueness*** for every participant.
* Each participant has ***in edges*** (who invited them) and ***out edges*** (who they invited) under their *self-sovereign control*. The union of all such is a ***decentralized social graph***
* Innovate and exploit ***multi-scale emergent self-governance*** using such graphs. More specifically, a ***social capital commons*** aka ***network cooperative***.
* Develop **ecosystems of applications** that allow the participants to **self-organize at any scale around any shared purpose**.
* Form the first ***planetary political union*** that can **outcompete existing political parties** in any nation.
* Exploit our size for **financial, cultural,** and **political power**


### Achievable tactical outcomes
In starting small, momentum and network effects require incremental tactical outcomes that show progress in that strategy.

Examples:

* **An clear vision and agenda** - With a simple **collaborative ontology editor** (prototyped *here*), any number of participants large or small can show an immediate and evolving consensus on specific principles and positions. Whatever its current form is attracts more participation.
* **Bioregional mycelia** - Through **intention in the invitation process**, connections are created across every bioregion in the world. Those connections have many possible applications.
* **Citizen assemblies** - Any subnetwork can form around any common interest, e.g. bioregion, area of focus, etc. Such subnetworks can utilize and evolve citizen assemblies to great effect.
* **Legal structures** - Working groups can create legal manifestations, e.g. a political party, even at small scales.
* **Take over the US Democratic Party from within** - Work with existing entities like **[RunForSomething](https://runforsomething.net/)** and **[Justice Democrats](https://justicedemocrats.com/)** to recruit candidates who ratify the agenda, and support them with the power of **planetary allies**. The Democrats are moribund in their current form, and drastically need re-definition.
* ...

### Planetary potential
What is the potential to truly manifest **an attractor that can make a difference**?

We believe the combination of a **clearly articulated vision** and a provably **large number of signatories** can **surpass the Catholic Church** in planetary cultural, financial, and political agency. 

It can be vastly **better at emergent coordination**, by virtue of the **network topology** of its citizenry. 

And its vision will be universally attractive as **a worldview worth working toward together**. 





`;
export default markdown;
