import React from 'react';
import RenderChakraMarkdown from 'utils/ChakraMarkdown';
import {
  Text,
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading
} from '@chakra-ui/react';
import platform_taxonomy from 'shared/data/platform_chatgpt_nested';

export const OneItem = props => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Heading as='h4' fontSize={'lg'} sx={{ fontFamily: 'Helvetica', color: '#ff0000' }}>
          {props.heading}
        </Heading>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel sx={{ fontSize: ['14px', '18px'] }}>
        {typeof props.body == 'string' ? (
          <RenderChakraMarkdown key='body'>{props.body}</RenderChakraMarkdown>
        ) : (
          props.body
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

const OneAccordion = props => {
  // console.log('PROPS', props);
  return (
    <Accordion className='accordion' allowToggle width={['90vw', '82vw', '60vw']}>
      {props.items}
    </Accordion>
  );
};

const JsonAccordion = props => {
  // console.log('JSON', platform_taxonomy);
  const index = props.index || 0;

  const items = [];

  /*   parts.forEach((part, i) => {
    let atoms = part.split('\n');
    const heading = atoms.shift();
    const body = atoms.join('\n');
    if (heading && body) items.push(<OneItem heading={heading} body={body} />);
  }); */
  //
  Object.keys(platform_taxonomy).forEach(key => {
    const value = platform_taxonomy[key];
    const policies = Object.keys(value);
    const items2 = [];
    // console.log('pol', policies);
    policies.forEach(one => {
      const val = value[one].policies;
      console.log('VAL', val);
      items2.push(<OneItem heading={one} body={JSON.stringify(val)} />);
    });
    // console.log('ITMES2', items2);
    items.push(<OneItem heading={key} body={<OneAccordion items={items2}></OneAccordion>} />);
  });

  return (
    <Accordion
      className='accordion'
      defaultIndex={index}
      allowToggle
      width={['90vw', '82vw', '60vw']}
    >
      {items}
    </Accordion>
  );
};

export default JsonAccordion;
