const platform_taxonomy = {
  economic_and_social_justice: {
    wealth_inequality: {
      organizations: ['Oxfam', 'The Institute for Policy Studies', 'Americans for Tax Fairness'],
      policies: [
        'Implement progressive taxation with higher rates on wealth and capital gains.',
        'Cap executive compensation ratios within companies.',
        'Strengthen regulations on offshore tax havens and tax evasion.'
      ]
    },
    racial_justice: {
      organizations: ['NAACP', 'Black Lives Matter', 'Equal Justice Initiative'],
      policies: [
        'Enforce anti-discrimination laws in housing, employment, and education.',
        'Support community-based initiatives for racial equity and justice.',
        'Fund reparations and economic development programs for affected communities.'
      ]
    },
    'post-capitalism': {
      organizations: [
        'Democracy at Work Institute',
        'Solidarity Economy Network',
        'New Economy Coalition'
      ],
      policies: [
        'Promote cooperative enterprises and worker-owned businesses.',
        'Support community-based economic models and local currencies.',
        'Implement policies that prioritize social and environmental well-being over profit.'
      ]
    },
    solidarity: {
      organizations: [
        'Global Justice Now',
        'Solidarity Center',
        'International Labor Organization (ILO)'
      ],
      policies: [
        'Promote global solidarity and cooperation among workers and marginalized groups.',
        'Support fair trade practices and international labor rights.',
        'Advocate for policies that reduce inequalities and promote economic justice.'
      ]
    },
    reparations: {
      organizations: [
        "National Coalition of Blacks for Reparations in America (N'COBRA)",
        'Reparations United Front',
        'Reparations NOW!'
      ],
      policies: [
        'Advocate for reparations for historical injustices, including slavery and colonialism.',
        'Support economic and educational initiatives targeted at affected communities.',
        'Establish truth and reconciliation commissions to address historical injustices.'
      ]
    },
    universal_basic_income: {
      organizations: ['Basic Income Earth Network', 'Universal Income Project', 'Income Movement'],
      policies: [
        'Pilot and implement universal basic income programs.',
        'Establish a robust social security net that ensures economic security for all.',
        'Guarantee access to affordable housing, healthcare, and education.'
      ]
    }
  },
  environmental_and_biodiversity_protection: {
    unity_with_nature: {
      organizations: [
        'World Wildlife Fund (WWF)',
        'Conservation International',
        'Global Environmental Facility'
      ],
      policies: [
        'Expand protected areas and support biodiversity conservation efforts.',
        'Promote sustainable agriculture and reforestation initiatives.',
        'Invest in green infrastructure and renewable energy projects.'
      ]
    },
    regeneration_and_rewilding_of_natural_systems: {
      organizations: [
        'Regeneration International',
        'Rewilding Europe',
        'Wildlife Conservation Society'
      ],
      policies: [
        'Restore degraded ecosystems and establish wildlife corridors.',
        'Promote regenerative agriculture and sustainable land management practices.',
        'Phase out subsidies for environmentally harmful industries.'
      ]
    },
    biodiversity_protection: {
      organizations: [
        'International Union for Conservation of Nature (IUCN)',
        'Wildlife Conservation Society',
        'Convention on Biological Diversity (CBD)'
      ],
      policies: [
        'Strengthen enforcement against illegal wildlife trade and habitat destruction.',
        'Expand marine protected areas and sustainable fisheries management.',
        'Support species conservation and genetic diversity preservation efforts.'
      ]
    },
    half_earth: {
      organizations: [
        'Half Earth Project',
        'E.O. Wilson Biodiversity Foundation',
        'Conservation International'
      ],
      policies: [
        "Set ambitious targets for conserving half of the Earth's land and oceans.",
        'Establish protected areas and wildlife corridors.',
        'Support biodiversity research and conservation initiatives.'
      ]
    },
    rights_of_nature: {
      organizations: [
        'Global Alliance for the Rights of Nature',
        'Earth Law Center',
        'International Union for Conservation of Nature (IUCN)'
      ],
      policies: [
        'Recognize legal rights of ecosystems and natural entities.',
        'Enforce environmental laws and regulations to protect natural habitats.',
        'Promote rights-based approaches to conservation and sustainable development.'
      ]
    }
  },
  global_and_local_governance: {
    bioregional_and_planetary_and_emergent_governance: {
      organizations: [
        'Global Ecovillage Network',
        'International Union for Conservation of Nature (IUCN)',
        'Transition Network'
      ],
      policies: [
        'Advocate for decentralized governance models prioritizing local and planetary well-being.',
        'Strengthen international environmental treaties and agreements.',
        'Support bioregional planning and community-driven sustainability initiatives.'
      ]
    },
    beyond_nation_states: {
      organizations: [
        'World Federalist Movement',
        'Transnational Institute',
        'Global Challenges Foundation'
      ],
      policies: [
        'Advocate for global governance structures beyond nation-states.',
        'Strengthen international cooperation on global challenges like climate change and pandemics.',
        'Promote human rights and global justice frameworks.'
      ]
    },
    participatory_democracy: {
      organizations: [
        'Participedia',
        'International Institute for Democracy and Electoral Assistance (IDEA)',
        'The Participatory Budgeting Project'
      ],
      policies: [
        'Strengthen citizen participation in decision-making processes.',
        'Promote participatory budgeting and deliberative democracy.',
        'Support local governance structures and community-led initiatives.'
      ]
    },
    citizen_assemblies: {
      organizations: ['Sortition Foundation', 'Healthy Democracy', 'Democracy R&D'],
      policies: [
        'Promote random selection and citizen assemblies for decision-making.',
        'Ensure diverse representation and inclusive participation.',
        'Support deliberative processes and democratic reforms.'
      ]
    }
  },
  technology_and_innovation: {
    automation: {
      organizations: [
        'Future of Life Institute',
        'Brookings Institution',
        'International Federation of Robotics'
      ],
      policies: [
        'Implement policies for retraining and reskilling workers displaced by automation.',
        'Explore universal basic income and other forms of social security.',
        'Regulate the deployment of AI and automation to ensure ethical use and societal benefit.'
      ]
    },
    artificial_intelligence_threat_and_governance: {
      organizations: [
        'Future of Humanity Institute',
        'Center for Human-Compatible AI',
        'Partnership on AI'
      ],
      policies: [
        'Develop international norms and regulations for AI development and deployment.',
        'Ensure transparency and accountability in AI algorithms and decision-making.',
        'Promote research on AI safety, ethics, and governance.'
      ]
    },
    energy_innovation: {
      organizations: [
        'International Renewable Energy Agency (IRENA)',
        'Rocky Mountain Institute',
        'Energy Innovation Reform Project'
      ],
      policies: [
        'Invest in research and development of renewable energy technologies.',
        'Promote energy efficiency and grid modernization.',
        'Support policies that accelerate the transition to a low-carbon energy system.'
      ]
    }
  },
  community_and_cooperative_economy: {
    mutual_aid_networks: {
      organizations: [
        'Mutual Aid Disaster Relief',
        'Cooperative Development Institute',
        'Solidarity Economy Network'
      ],
      policies: [
        'Build and support mutual aid networks for community resilience and solidarity.',
        'Promote cooperative economies and shared resources.',
        'Ensure equitable access to healthcare, housing, and education through mutual aid.'
      ]
    },
    circular_economies: {
      organizations: [
        'Ellen MacArthur Foundation',
        'Post Growth Institute',
        'Doughnut Economics Action Lab'
      ],
      policies: [
        'Promote circular economy principles and sustainable consumption patterns.',
        'Invest in research and development of green technologies and alternative economic models.',
        'Support worker cooperatives and community-owned enterprises.'
      ]
    },
    commons_stewardship_of_shared_resources: {
      organizations: ['Global Commons Alliance', 'Commons Network', 'Commons Transition'],
      policies: [
        'Support community-led management of commons resources.',
        'Establish legal frameworks to protect and sustainably manage shared resources.',
        'Promote participatory governance and local decision-making.'
      ]
    },
    shared_abundance: {
      organizations: ['Shareable', 'Commons Network', 'Common Wealth Trust'],
      policies: [
        'Promote sharing economies and collaborative consumption.',
        'Support cooperative ownership models and community wealth-building.',
        'Redistribute resources and wealth to ensure equitable access and prosperity.'
      ]
    }
  },
  demilitarization_and_peace: {
    demilitarization: {
      organizations: [
        'Ploughshares Fund',
        'International Campaign to Abolish Nuclear Weapons (ICAN)',
        'Peace Direct'
      ],
      policies: [
        'Reduce military spending and reallocate resources to social welfare and development.',
        'Support arms control treaties and disarmament initiatives.',
        'Promote non-violent conflict resolution and peacebuilding efforts.'
      ]
    },
    police_reform: {
      organizations: [
        'Campaign Zero',
        'Equal Justice Initiative',
        'Police Executive Research Forum (PERF)'
      ],
      policies: [
        'Implement community policing strategies and accountability mechanisms.',
        'Support training programs on de-escalation techniques and cultural sensitivity.',
        'Advocate for reforms to reduce police brutality and racial profiling.'
      ]
    }
  },
  sustainable_living_and_education: {
    food_systems: {
      organizations: [
        'Food and Agriculture Organization (FAO)',
        'World Food Programme (WFP)',
        'Slow Food International'
      ],
      policies: [
        'Promote sustainable agriculture and agroecological practices.',
        'Support local food systems and reduce food waste.',
        'Ensure food security and nutrition for all.'
      ]
    },
    alternatives_to_meat: {
      organizations: ['Good Food Institute', 'Plant Based News', 'Alternative Protein Show'],
      policies: [
        'Invest in research and development of plant-based and cultured meat alternatives.',
        'Promote dietary shifts towards plant-based diets for environmental and health benefits.',
        'Support policies that incentivize sustainable protein sources.'
      ]
    },
    universal_healthcare: {
      organizations: [
        'World Health Organization (WHO)',
        'Physicians for a National Health Program',
        'National Nurses United'
      ],
      policies: [
        'Establish universal healthcare coverage for all citizens.',
        'Invest in preventive care and public health initiatives.',
        'Ensure equitable access to healthcare services and medicines.'
      ]
    },
    universal_education: {
      organizations: ['UNESCO', 'Global Partnership for Education', 'Teach For All'],
      policies: [
        'Provide free and accessible education from early childhood to higher education.',
        'Support teachers and educational infrastructure development globally.',
        'Promote lifelong learning and vocational training opportunities.'
      ]
    }
  }
};

export default platform_taxonomy;
